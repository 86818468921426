import {createGlobalStyle} from 'styled-components';
import GlobalColors from './Colors';
import {GlobalFonts} from './Fonts';

const GlobalStyles = createGlobalStyle`
  .full-width {
    width: 100% !important;
  }
  .center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center-in-column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .no-margin {
    margin: 0 !important;
  }
  .no-padding {
    padding: 0 !important;
  }
  .no-padding-desk {
    @media(min-width: 300px) and (max-width: 1023px){
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    @media(min-width: 1024px){
      padding: 0 !important;
    }
  }
  .no-padding-left {
    padding-left: 0 !important;
  }
  .no-padding-right {
    padding-right: 0 !important;
  }
  .no-padding-top-mobile {
    @media(min-width: 300px) and (max-width: 1023px){
      padding-top: 0 !important;
    }
  }
  .grid-fit{
    width: 100% !important;
    margin-left: 0 !important;
  }

  .no-show-mobile {
    @media(min-width: 300px) and (max-width: 1023px){
      display: none !important;
    }
    @media(min-width: 1024px){
      display: block !important;
    }
  }
  .no-show-desk {
    @media(min-width: 1024px){
      display: none !important;
    }
  }

  .container-fit {
    @media(min-width: 300px) and (max-width: 767px){
      margin: 0 20px !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      margin: 0 20px !important;
    }
    @media(min-width: 1024px) and (max-width: 1279px){
      margin: 0 50px !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      margin: 0 100px !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      margin: 0 100px !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      margin: 0 100px !important;
    }
    @media(min-width: 1920px) and (max-width: 3200px){
      margin: 0 200px !important;
    }
  }

  .container-fit-spacing {
    @media(min-width: 300px) and (max-width: 767px){
      margin: 20px 20px !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      margin: 20px 20px !important;
    }
    @media(min-width: 1024px) and (max-width: 1279px){
      margin: 40px 50px !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      margin: 40px 100px !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      margin: 40px 100px !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      margin: 40px 100px !important;
    }
    @media(min-width: 1920px) and (max-width: 3200px){
      margin: 40px 200px !important;
    }
  }

  .alice-carousel__stage-item > div.item {
    background-position: center center;
  }

  .button-gradient {
    font: 15px ${GlobalFonts.fontBold};
    color: ${GlobalColors.colorBtnTextPrimary};
    color: #fff;
    display: block;
    margin-right: 20px;
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: 0.3s ease all;
    background: linear-gradient(
        73.69deg,
          #5433ff -18.91%,
        #20bdff 65.98%,
        #a5fecb 124.27%
    );
    border-radius: 10px;

    &:hover {
      background: linear-gradient(
          298.69deg,
            #5433ff -18.91%,
          #20bdff 65.98%,
          #a5fecb 124.27%
      );
    }
    &:active{
      background: ${GlobalColors.colorSecondary};
    }
    @media (min-width: 300px) and (max-width: 767px) {
      margin: 0px;
      font-size: 13px;
      width: 215px;
      height: 50px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 13px;
      width: 200px;
      height: 50px;
    }

    @media (min-width: 1024px) and (max-width: 1079px) {
      font-size: 13px;
      width: 200px;
      height: 50px;
    }
    @media (min-width: 1080px) and (max-width: 1219px) {
      font-size: 13px;
      width: 200px;
      height: 60px;
    }
    @media (min-width: 1280px) and (max-width: 1407px) {
      font-size: 13px;
      width: 200px;
      height: 70px;
    }
    @media (min-width: 1408px) and (max-width: 1439px) {
      font-size: 13px;
      width: 200px;
      height: 70px;
    }
    @media (min-width: 1440px) and (max-width: 1919px) {
      font-size: 15px;
      width: 300px;
      height: 70px;
    }
    @media (min-width: 1920px) {
      width: 300px;
      height: 70px;
    }
  }

  .head-fit{
  width: auto;
  height: auto;
  //padding: 0 25px;
  padding: 184px 0 70px;

  @media (min-width: 300px) and (max-width: 767px) {
    padding: 80px 0 50px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 100px 0 50px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    padding: 110px 0 50px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    padding: 120px 0 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    padding: 140px 0 50px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    padding: 140px 0 50px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    padding: 140px 0 50px;
  }
  }


  .padding-form-fit{
  @media (min-width: 300px) and (max-width: 767px) {
    padding: 40px 30px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 40px 30px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    padding: 40px 30px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    padding: 40px 30px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    padding: 40px 40px;
  }
  @media (min-width: 1408px) and (max-width: 1699px) {
    padding: 45px 45px;
  }
  @media (min-width: 1700px) {
    padding: 45px 110px;
  }
  }

  `

export default GlobalStyles;
