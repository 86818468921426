const GlobalColors = {
  colorMenuPrimary: `#1F375B`,
  colorMenuSecondary: `#20BDFF`,
  colorMenuMobilePrimary: `#1F375B`,
  colorMenuMobileSecondary: `#20BDFF`,
  colorBorder: `#20BDFF`,
  colorBorderDark: `#4DCAFF`,
  colorPrimary: `#1F375B`,
  colorPrimaryLight: `#D2D7DE`,
  colorPrimaryDark: `#4C5F7C`,
  colorSecondary: `#20BDFF`,
  colorSecondaryLight: `#D2F2FF`,
  colorSecondaryDark: `#4DCAFF`,
  colorSuccess: `#A5FECB`,
  colorDanger: `#EB5757`,
  colorDangerDark: `#EB5757`,
  colorWarning: `#f7b731`,
  colorInfo: `#5433FF`,
  colorLight: `#f7f9fc`,
  colorDark: `#4F4F4F`,
  colorShadowRGB: `75, 101, 132`,
  colorLink: `#1F375B`,
  colorLinkHover: `#79879D`,
  colorBtnTextPrimary: `#FFFFFF`,
  colorBtnTextPrimaryHover: `#E7E7E7`,
  colorText: `#1F375B`,
  colorTextSecondary: `#20BDFF`,
  colorTextDiscreet: `#BDBAE4`,
  colorMenuFooterPrimary: `#F4F3FF`,
  gradientColor: `linear-gradient(to top right, #5433ff 0%, #a5fecb 100%)`,
}
export default GlobalColors;
