import React from 'react';
import {
  ContImgProvee,
  ContImgsProveedores,
  ContInfo,
  ContProveedores,
  ContProveedoresInner,
  ContTitle,
  Desc, ImgProvee,
  Title1,
  Title2,
} from './NuestrosProveedores.styles';
import {Grid} from '@mui/material';
import ImgCriotect from '../../assets/images/provee-criotec.svg';
import ImgNissan from '../../assets/images/provee-nissan.svg';
import ImgTorrey from '../../assets/images/provee-torrey.svg';
import ImgImbera from '../../assets/images/provee-imbera.svg';
import ImgProtek from '../../assets/images/provee-protek.svg';
import ImgRhino from '../../assets/images/provee-rhino.svg';

const NuestrosProveedores = () => {

  return (
    <ContProveedores id='proveedores' className={'container-fit'}>
      <ContProveedoresInner maxWidth={false} className={'no-padding-desk'}>
        <ContTitle>
          <Title1>Marcas con las <Title2>que trabajamos</Title2></Title1>
        </ContTitle>
        <ContInfo>
          <Desc>Contamos con más de 20 proovedores afiliados que confian en nosotros.</Desc>
        </ContInfo>
        <ContImgsProveedores>
          <Grid container spacing={1} className={'grid-fit center-in-column'}>
            <Grid item md={2} className={'center no-padding-left no-padding-top-mobile'}>
              <ContImgProvee>
                <ImgProvee src={ImgTorrey} alt=''/>
              </ContImgProvee>
            </Grid>
            <Grid item md={2} className={'center no-padding-left no-padding-top-mobile'}>
              <ContImgProvee>
                <ImgProvee src={ImgCriotect} alt=''/>
              </ContImgProvee>
            </Grid>
            <Grid item md={2} className={'center no-padding-left no-padding-top-mobile'}>
              <ContImgProvee>
                <ImgProvee src={ImgNissan} alt=''/>
              </ContImgProvee>
            </Grid>
            <Grid item md={2} className={'center no-padding-left no-padding-top-mobile'}>
              <ContImgProvee>
                <ImgProvee src={ImgImbera} alt=''/>
              </ContImgProvee>
            </Grid>
            <Grid item md={2} className={'center no-padding-left no-padding-top-mobile'}>
              <ContImgProvee>
                <ImgProvee src={ImgProtek} alt=''/>
              </ContImgProvee>
            </Grid>
            <Grid item md={2} className={'center no-padding-left no-padding-top-mobile'}>
              <ContImgProvee>
                <ImgProvee src={ImgRhino} alt=''/>
              </ContImgProvee>
            </Grid>
          </Grid>
        </ContImgsProveedores>
      </ContProveedoresInner>
    </ContProveedores>
  );
};

export default NuestrosProveedores;
