import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContQueHacemos = styled.div`
  width: auto;
  height: auto;
  padding: 100px 0 70px;

  @media (min-width: 300px) and (max-width: 767px) {
    padding: 70px 0 50px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 55px 0 50px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    padding: 70px 0 50px;
  }
  @media (min-width: 1080px) and (max-width: 1279px) {
    padding: 90px 0 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    padding: 70px 0 50px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    padding: 70px 0 50px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    padding: 70px 0 50px;
  }
`;
export const ContQueHacemosInner = styled.div`
  width: auto;
  height: 100%;
  position: relative;
`;
export const ContTitle = styled.div`
  font: 45px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;

  @media (min-width: 300px) and (max-width: 767px) {
    margin: 0 20px 25px;
    font-size: 29px;
    line-height: 46px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 29px;
    line-height: 58px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 35px;
    line-height: 36px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 40px;
    line-height: 38px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 45px;
    line-height: 42px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 45px;
    line-height: 46px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 48px;
    line-height: 54px;
  }
  @media (min-width: 1920px) {
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 50px;
    line-height: 70px;
  }
`;
export const Title1 = styled.div`
  color: ${GlobalColors.colorPrimary};
  margin-bottom: 15px;
`;
export const Title2 = styled.span`
  color: ${GlobalColors.colorSecondary};
`;
export const ContInfo = styled.div`
  width: 100%;

  @media (min-width: 300px) and (max-width: 1023px) {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const DescBold = styled.div`
  font-weight: 700;
  margin-top: 15px
`
export const Desc = styled.div`
  font: 18px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  color: ${GlobalColors.colorPrimary};

  @media (min-width: 300px) and (max-width: 767px) {
    margin: 0px 0px 35px 0px;
    line-height: 26px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0px 10px 35px 10px;
    line-height: 25px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    margin: 0px 30px 35px 30px;
    line-height: 24px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    margin: 0px 30px 35px 30px;
    line-height: 26px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    margin: 0px 30px 35px 30px;
    line-height: 26px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    margin: 0px 100px 35px 100px;
    line-height: 26px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    margin: 0px 100px 35px 100px;
    line-height: 26px;
  }
  @media (min-width: 1920px) {
    margin-bottom: 30px;
    line-height: 30px;
  }
`;
export const ImgFront = styled.img`
  width: 70%;
  height: 70%;
  margin-bottom: 10px;
  min-width: 246px;
`
