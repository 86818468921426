import styled from "styled-components";
import { GlobalColors, GlobalFonts } from "../../../globals";

export const ContNegocio = styled.div`
  padding-bottom: 60px;
  @media (min-width: 300px) and (max-width: 767px) {
    padding-bottom: 35px;
    width: auto;
    height: auto;
  }
  @media (min-width: 768px) and (max-width: 1219px) {
    width: auto;
    height: auto;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    padding-bottom: 100px;
    width: auto;
    height: 510px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    width: auto;
    height: 550px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    width: 100%;
    height: 638px;
  }
  @media (min-width: 1920px) {
    width: auto;
    height: 638px;
  }
`;

export const ContImgFirst = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 300px) and (max-width: 767px) {
    width: 100%;
    height: 100%
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    height: 100%
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    position: absolute;
    margin-top: -20px;
    width: 380px;
    height: 380px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    position: absolute;
    margin-top: -20px;
    width: 400px;
    height: 400px;
  }
  @media (min-width: 1220px) and (max-width: 1407px) {
    position: absolute;
    margin-top: -20px;
    width: 500px;
    height: 500px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    position: absolute;
    width: 550px;
    height: 550px;
  }
  @media (min-width: 1440px) and (max-width: 1679px) {
    position: absolute;
    width: 560px;
    height: 560px;
  }
  @media (min-width: 1680px) and (max-width: 1919px) {
    position: absolute;
    width: 650px;
  height: 650px;
  }
  @media (min-width: 1920px) {
    position: absolute;
    width: 650px;
    height: 650px;
  }
`;

export const ImgTwo = styled.img`
    display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 440px;
    height: 440px;
  }
`;
export const ContSquare = styled.div`
  width: auto;
  height: auto;
`;
export const Square = styled.div`
  padding: 4px 4px 4px 4px;
  box-sizing: border-box;
  border-radius: 20px;
  background: rgb(84, 51, 255);
  background: linear-gradient(
    45deg,
    rgba(84, 51, 255, 1) 0%,
    rgba(32, 189, 255, 1) 5%,
    rgba(165, 254, 203, 1) 100%
  );

  @media (min-width: 300px) and (max-width: 767px) {
    width: 286.85px;
    height: 286.85px;
  }
  @media (min-width: 768px) and (max-width: 911px) {
    width: 358.15px;
    height: 358.15px;
  }
  @media (min-width: 912px) and (max-width: 1023px) {
    width: 320px;
    height: 320px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    line-height: 20px;
    width: 330px;
    height: 330px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    margin: 10px 0px 0px 30px;
    width: 320px;
    height: 320px;
  }
  @media (min-width: 1220px) and (max-width: 1407px) {
    margin: 10px 0px 0px 30px;
    width: 420px;
    height: 421px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    margin: 10px 0px 0px 30px;
    width: 450px;
    height: 450px;
  }
  @media (min-width: 1440px) and (max-width: 1679px) {
    margin: 10px 0px 0px 30px;
    width: 460px;
    height: 460px;
  }
  @media (min-width: 1680px) and (max-width: 1919px) {
    margin: 10px 0px 0px 30px;
    width: 500px;
    height: 500px;
  }
  @media (min-width: 1920px) {
    margin: 10px 0px 0px 30px;
    width: 536px;
    height: 536px;
  }
`;
export const SquareTwo = styled.div`
  width: auto;
  height: 100%;
  background: white;
  border-radius: 20px;
`;
export const ContTitle = styled.div`
  width: auto;
  height: auto;
  font: 36px ${GlobalFonts.fontBold};
  font-style: normal;
  line-height: 50px;
  text-align: start;

  @media (min-width: 300px) and (max-width: 767px) {
    font: 28px ${GlobalFonts.fontBold};
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  }
  @media (min-width: 768px) and (max-width: 911px) {
    padding: 0px 0px 0px 20px;
    font: 36px ${GlobalFonts.fontBold};
    text-align: center;
  }
  @media (min-width: 912px) and (max-width: 1023px) {
    padding: 0px 0px 0px 25px;
    font: 32px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    font: 26px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1080px) and (max-width: 1407px) {
    font: 28px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1408px) and (max-width: 1919px) {
    font: 36px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1920px) {
    width: auto;
  }
`;
export const Title1 = styled.div`
  color: ${GlobalColors.colorPrimary};
  @media (min-width: 300px) and (max-width: 767px) {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  }
`;
export const Desc = styled.div`
  padding-top: 10px;
  font: 24px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  color: ${GlobalColors.colorPrimary};
  top: 515px;

  @media (min-width: 300px) and (max-width: 767px) {
    padding: 20px 0px 30px 0px;
    font: 20px ${GlobalFonts.fontMedium};
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 911px) {
    padding: 13px 0px 40px 0px;
    text-align: center;
    font: 24px ${GlobalFonts.fontMedium};
  }
  @media (min-width: 912px) and (max-width: 1023px) {
    padding: 15px 0px 10px 25px;
    font: 20px ${GlobalFonts.fontMedium};
  }
  @media (min-width: 1024px) and (max-width: 1439px) {
    padding: 13px 0px 0px 0px;
    font: 20px ${GlobalFonts.fontMedium};
  }
  @media (min-width: 1440px) and (max-width: 1599px) {
    padding: 13px 0px 0px 0px;
    font: 22px ${GlobalFonts.fontMedium};
  }
`;
export const ContButton = styled.div`
  padding-top: 50px;
  width: 100%;
  height: auto;
  padding-bottom: 50px !important;
  display: flex;
  align-items: center;
  
  @media (min-width: 300px) and (max-width: 767px) {
    width: auto;
    height: auto;
    justify-content: center;
  }
  @media (min-width: 768px) and (max-width: 1079px) {
    padding-top: 30px;
    justify-content: space-around;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    justify-content: start;
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
  padding-top: 30px;
    width: 100%;
  }
  @media (min-width: 1408px) and (max-width: 1919px) {
    width: 100%;
    padding-top: 30px;
  }
  @media (min-width: 1920px) {
    width: 100%;
  }
`;

export const Boton = styled.button`
  font: 15px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorBtnTextPrimary};
  color: #fff;
  width: 215px;
  height: 70px;
  display: block;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s ease all;
  background: linear-gradient(
    73.69deg,
    #5433ff -18.91%,
    #20bdff 65.98%,
    #a5fecb 124.27%
  );
  border-radius: 10px;

  &:hover {
    background: linear-gradient(
      298.69deg,
      #5433ff -18.91%,
      #20bdff 65.98%,
      #a5fecb 124.27%
    );
  }
  @media (min-width: 300px) and (max-width: 767px) {
    margin-right: 15px;
    width: 160px;
    height: 70px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 215px;
    height: 70px;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    width: 215px;
    height: 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    width: 215px;
    height: 55px;
  }
  @media (min-width: 1408px) and (max-width: 1919px) {
    width: 215px;
    height: 60px;
  }
  @media (min-width: 1920px) {
    width: 215px;
  }
`;
export const ALink = styled.div`
  width: 215px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s ease all;
  background: linear-gradient(
    73.69deg,
    #5433ff -18.91%,
    #20bdff 65.98%,
    #a5fecb 124.27%
  );
  border-radius: 10px;

  & > a {
    width: 100%;
    height: 100%;
    display: block;
    font: 15px ${GlobalFonts.fontBold};
    color: ${GlobalColors.colorBtnTextPrimary};
    color: #fff;
    text-decoration: unset;
  }
  
  &:hover {
    background: linear-gradient(
      298.69deg,
      #5433ff -18.91%,
      #20bdff 65.98%,
      #a5fecb 124.27%
    );
  }
  @media (min-width: 300px) and (max-width: 767px) {
    margin-right: 15px;
    width: 160px;
    height: 70px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 215px;
    height: 70px;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    width: 215px;
    height: 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    width: 215px;
    height: 55px;
  }
  @media (min-width: 1408px) and (max-width: 1919px) {
    width: 215px;
    height: 60px;
  }
  @media (min-width: 1920px) {
    width: 215px;
  }
`;
export const Contenedor = styled.div`
  padding-bottom: 50px;
`;
export const ContInner = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
`;
