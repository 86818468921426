import React from "react";
import Layout from "../components/Layout/";
import Footer from "../components/Footer";
import SolicitaCredito from '../components/SolicitaCredito';
import CasosExitoV2 from '../components/CasosExitoV2';
import Video from '../components/Video';

const CasosDeExito = () => {
  return (
      <Layout>
        <Video />
        <CasosExitoV2 />
        <SolicitaCredito/>
        <Footer />      
      </Layout>
  );
};

export default CasosDeExito;
