import styled, { createGlobalStyle } from "styled-components";
import { GlobalColors, GlobalFonts } from "../../globals";

export const StylesHome = createGlobalStyle`
  .cont-button {
    display: none !important;
    @media(min-width: 300px) and (max-width: 1023px) {
      width: 100%;
      margin: 15px 0 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }    
  }
  .alice-carousel__stage-item{
    @media(min-width: 1220px) and (max-width: 1279px) {
      height: 500px;
      padding-right: 25px;
      
    }
  }
  .alice-carousel{
    @media(min-width: 1220px) and (max-width: 1279px) {
      margin-left: -30px;
    } 
  }
`;

export const ContButton = styled.div`
  width: 100%;
  padding-bottom: 50px !important;
  display: flex;
  align-items: center;

  @media (min-width: 300px) and (max-width: 767px) {
    padding-bottom: 0px !important;
    justify-content: center;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    justify-content: center;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    justify-content: center;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    justify-content: start;
    width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    width: 100%;
  }
  @media (min-width: 1920px) {
    width: 100%;
  }
`;
export const ContHome = styled.div`
  width: auto;
  height: auto;
  //padding: 0 25px;
  padding: 184px 0 70px;

  @media (min-width: 300px) and (max-width: 767px) {
    padding: 80px 0 50px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 100px 0 50px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    padding: 110px 0 50px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    padding: 120px 0 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    padding: 140px 0 50px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    padding: 140px 0 50px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    padding: 140px 0 50px;
  }
`;
export const ContBlockLema = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;
export const ContLema = styled.div`
  width: auto;
  height: auto;
  margin-bottom: 15px;

  @media (min-width: 300px) and (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    text-align: center;
  }
`;
export const ContLema1 = styled.div`
  font: 72px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  color: ${GlobalColors.colorText};

  @media (min-width: 300px) and (max-width: 767px) {
    font: 34px ${GlobalFonts.fontBold};
    line-height: 40px;
    text-align: center;
  }
  @media (min-width: 767px) and (max-width: 911px) {
    font: 54px ${GlobalFonts.fontBold};
    line-height: 64px;
  }
  @media (min-width: 912px) and (max-width: 1023px) {
    font: 48px ${GlobalFonts.fontBold};
    line-height: 64px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    font: 48px ${GlobalFonts.fontBold};
    line-height: 56px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    font: 49px ${GlobalFonts.fontBold};
    line-height: 54px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    font: 54px ${GlobalFonts.fontBold};
    line-height: 60px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    font: 60px ${GlobalFonts.fontBold};
    line-height: 64px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    font: 62px ${GlobalFonts.fontBold};
    line-height: 68px;
  }
  @media (min-width: 1920px) and (max-width: 3200px) {
    font: 72px ${GlobalFonts.fontBold};
    line-height: 80px;
  }
`;
export const ContLema2 = styled.span`
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  color: ${GlobalColors.colorTextSecondary};

  @media (min-width: 300px) and (max-width: 767px) {
    font: 34px ${GlobalFonts.fontBold};
    line-height: 40px;
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 911px) {
    font: 54px ${GlobalFonts.fontBold};
    line-height: 64px;
  }
  @media (min-width: 912px) and (max-width: 1023px) {
    font: 48px ${GlobalFonts.fontBold};
    line-height: 64px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    font: 48px ${GlobalFonts.fontBold};
    line-height: 56px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    font: 49px ${GlobalFonts.fontBold};
    line-height: 54px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    font: 54px ${GlobalFonts.fontBold};
    line-height: 60px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    font: 60px ${GlobalFonts.fontBold};
    line-height: 64px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    font: 62px ${GlobalFonts.fontBold};
    line-height: 68px;
  }
  @media (min-width: 1920px) and (max-width: 3200px) {
    font: 72px ${GlobalFonts.fontBold};
    line-height: 80px;
  }
`;
export const ContBlockDesc = styled.div`
  padding-bottom: 50px;
  width: 100%;
`;
export const ContDesc = styled.div`
  font: 24px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: ${GlobalColors.colorText};

  @media (min-width: 300px) and (max-width: 767px) {
    font: 18px ${GlobalFonts.fontMedium};
    line-height: 24px;
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    text-align: center;
  }
`;
export const ContDescBold = styled.span`
  font: 24px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: ${GlobalColors.colorText};

  @media (min-width: 300px) and (max-width: 767px) {
    font: 18px ${GlobalFonts.fontBold};
    line-height: 24px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
  }
`;
export const ContImgFirst = styled.div`
  width: 100%;
  height: auto;
`;


export const ItemCarousel = styled.div`
  width: 100% !important;
  height: 100% !important;
  background-image: url("${(props) => props.bg}");
  background-size: contain;
  background-repeat: no-repeat;
  @media (min-width: 1220px) and (max-width: 1279px) {
    font-size: 13px;
    margin-left: 20px;
    width: 150px;
    height: 70px;
  }
`;
export const ContCarrusel = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
`;

export const BtnCarousel = styled.button`
  width: 70px;
  height: 70px;
  margin-right: 15px;
  background: linear-gradient(
    65.38deg,
    #5433ff -161.14%,
    #20bdff -13.51%,
    #a5fecb 125.08%
  );
  border: none !important;
  border-radius: 50%;
  cursor: pointer;
`;


export const ContenedorBotones = styled.div`
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

export const Boton = styled.button`
  font: 15px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorBtnTextPrimary};
  color: #fff;
  width: 215px;
  height: 70px;
  display: block;
  border: none;
  margin-right: 15px;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s ease all;
  background: linear-gradient(
    73.69deg,
    #5433ff -18.91%,
    #20bdff 65.98%,
    #a5fecb 124.27%
  );
  border-radius: 10px;

  &:hover {
    background: linear-gradient(
      298.69deg,
      #5433ff -18.91%,
      #20bdff 65.98%,
      #a5fecb 124.27%
    );
  }
  @media (min-width: 300px) and (max-width: 767px) {
    margin-right: 15px;
    width: 160px;
    height: 70px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 215px;
    height: 70px;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    height: 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    height: 55px;
  }
  @media (min-width: 1408px) and (max-width: 1919px) {
    height: 60px;
  }
  @media (min-width: 1920px) {
  }
`;
