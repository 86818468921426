import React from "react";
import {
  Equipo,
  TitleEquipo1,
  TitleEquipo2,
  EquipoTitle,
  Contenido,
} from "./ItemEquipo.styles";

const ItemEquipo = ({ Title1, Title2, ContenidoText }) => {
  return (
    <Equipo>
      <EquipoTitle>
        <TitleEquipo1>
          {Title1} <TitleEquipo2>{Title2}</TitleEquipo2>{" "}
        </TitleEquipo1>
      </EquipoTitle>
      <Contenido>{ContenidoText} </Contenido>
    </Equipo>
  );
};

export default ItemEquipo;
