import React from 'react';
import imgLogo from '../../assets/images/logo-enpagos.svg';
import imgLogoBlanco from '../../assets/images/logo-enpagos-blanco.svg';
import {ContLogo, ImgLogo} from './Logo.styles';
import { Link } from 'react-router-dom';
const Logo = ({type = 'normal', style = {}}) => {

  return (
    <ContLogo style={style}>
      <Link to='/'>
      <ImgLogo  src={type === 'normal' ? imgLogo :  imgLogoBlanco} alt='' />
      </Link>
    </ContLogo>
  );
};

export default Logo;
