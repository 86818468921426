import React from 'react';
import imgLogoBlanco from '../../assets/images/logo-enpagos-blanco.svg';
import {ContLogo, ImgLogo} from './LogoBlanco.styles';
const LogoBlanco = () => {

  return (
    <ContLogo>
      <ImgLogo src={imgLogoBlanco} alt='' />
    </ContLogo>
  );
};

export default LogoBlanco;
