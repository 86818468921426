import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContPendingResult = styled.div`
  width: auto;
  height: auto;
`
export const ContResult = styled.div`
  width: auto;
  height: auto;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ContTitle = styled.div`
  font: 36px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  text-align: center;

  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 40px;
    margin-bottom: 15px;
    font-size: 26px;
    line-height: 34px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 40px;
    margin-bottom: 18px;
    font-size: 38px;
    line-height: 46px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 25px;
    margin-bottom: 18px;
    font-size: 22px;
    line-height: 30px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding-top: 25px;
    margin-bottom: 18px;
    font-size: 24px;
    line-height: 32px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 25px;
    margin-bottom: 18px;
    font-size: 28px;
    line-height: 38px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 25px;
    margin-bottom: 18px;
    font-size: 32px;
    line-height: 40px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 60px;
    margin-bottom: 22px;
    font-size: 32px;
    line-height: 40px;
  }
  @media(min-width: 1920px){
    padding-top: 30px;
    margin-bottom: 22px;
    font-size: 36px;
    line-height: 42px;
  }
`
export const Title1 = styled.div`
  color: ${GlobalColors.colorPrimary};
`
export const Title2 = styled.div`
  color: ${GlobalColors.colorSecondary};
`
export const ContInfo = styled.div`
  width: 100%;
  
  @media(min-width: 300px) and (max-width: 1023px){
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const Desc = styled.div`
  font: 24px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    margin-bottom: 30px;
    font-size: 12px;
    line-height: 22px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 70%;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 24px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 22px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
  }
  @media(min-width: 1920px){
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 26px;
  }
`
export const ContImgResult = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    width: 70%;
    height: auto;
    margin-top: 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 420px;
    height: 390px;
    margin-top: 35px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 320px;
    height: auto;
    margin-top: 35px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 260px;
    height: auto;
    margin-top: 30px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 290px;
    height: auto;
    margin-top: 20px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 350px;
    height: 390px;
    margin-top: 110px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 300px;
    height: auto;
    margin-top: 50px;
  }
  @media(min-width: 1920px){
    width: 420px;
    height: 390px;
    margin-top: 95px;
  }

  &>img {
    width: 100%;
    height: auto;
  }
`
