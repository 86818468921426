import styled, {css} from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
import {Container} from '@mui/material';
import imgCircle1 from  '../../assets/images/circle-1.svg';
import imgCircle2 from  '../../assets/images/circle-2.svg';
import imgDegraded1 from  '../../assets/images/degraded-1.svg';
import imgDegraded2 from  '../../assets/images/degraded-2.svg';


export const ContBeneficios = styled.div`
  position: relative;
  width: auto;
  height: auto;
  margin-top: 40px !important;
`
export const ContBeneficiosInner = styled(Container)`
  position: relative;
  padding: 30px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 1;
  
  @media(min-width: 300px) and (max-width: 767px){
  }
  @media(min-width: 768px) and (max-width: 1023px){
  }
  @media(min-width: 1024px) and (max-width: 1079px){
  }
  @media(min-width: 1080px) and (max-width: 1219px){
  }
  @media(min-width: 1220px) and (max-width: 1279px){
    padding-bottom: 0px !important;
    margin: 40px 0px 40px 0px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
  }
  @media(min-width: 1408px) and (max-width: 1439px){
  }
  @media(min-width: 1440px) and (max-width: 1919px){
  }
  @media(min-width: 1920px) and (max-width: 3200px){
  }
`
export const cfgBacks = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  z-index: 0;
`
export const ContBack = styled.div`
  background: ${GlobalColors.colorPrimary};
  border-radius: 20px;
  ${cfgBacks}
`
export const ContCircle1 = styled.div`
  background-image: url(${imgCircle1});
  background-position: top left;
  @media(min-width: 300px) and (max-width: 767px){
    background-size: 15%;
  }
  ${cfgBacks}
`
export const ContCircle2 = styled.div`
  background-image: url(${imgCircle2});
  background-position: bottom right;
  @media(min-width: 300px) and (max-width: 767px){
    background-size: 15%;
  }
  ${cfgBacks}
`
export const ContDegraded1 = styled.div`
  background-image: url(${imgDegraded1});
  background-position: bottom left;
  ${cfgBacks}
`
export const ContDegraded2 = styled.div`
  background-image: url(${imgDegraded2});
  background-position: top right;
  ${cfgBacks}
`
export const ContTitle = styled.div`
  display: flex;
  flex-direction: row;
  font: 56px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  margin-bottom: 10px;
  
`
export const Title1 = styled.div`
  display: flex;
  font: 56px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  color: ${GlobalColors.colorBtnTextPrimary};
`
export const Title2 = styled.div`
  color: ${GlobalColors.colorSecondary};
`

export const ItemMetric = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const Subtitle = styled.div`
  font: 14px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 200;
  text-align: center;
  color: ${GlobalColors.colorLight};
  margin-bottom: 10px;
`

export const ContMetric = styled.div`
display:flex;
flex-wrap: wrap;
justify-content: space-evenly;

`
