import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

function SwitchControl(props) {
  const {
    events = {},
    label,
    name,
    onChange,
    size,
    type,
    value,
  } = props;

  const Control = type === 'checkbox'
    ? Checkbox
    : Switch;

  const handleChange = ({ target: { checked } }) => {
    const target = {
      name,
      value: checked,
    };
    onChange({
      target,
    });

    if (events.onChange) {
      events.onChange(target);
    }
  };

  return (
    <FormControlLabel
      control={<Control
        checked={value}
        name={name}
        size={size}
        onChange={handleChange}
      />}
      label={label}
    />
  );
}

SwitchControl.defaultProps = {
  size: 'small',
  type: 'switch',
  value: false,
  onChange: () => { },
};

SwitchControl.propTypes = {
  events: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  type: PropTypes.oneOf([
    'checkbox',
    'switch',
  ]),
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SwitchControl;
