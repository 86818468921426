import React, { useEffect, useState } from "react";
import {
  ContCasos,
  ContCasosInner,
  ContInfo,
  ContSlider,
  ContTitle,
  Desc,
  DescBold,
  Title1,
  Title2,
  BtnCarousel,
  ItemCarousel,
  StylesCasos,
  ItemPicture,
  ContItemPicture,
  ItemName,
  ItemBusiness,
  ItemComment,
  ItemDot,
  ContIcoComment,
  ItemCarouselInner,
} from "./CasosExito.styles";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ImgArrowPrev from "../../assets/images/ico-arrow-prev.svg";
import ImgArrowNext from "../../assets/images/ico-arrow-next.svg";
import { useWindowDimensions } from "../../utils";
import IcoCommentFile from "../../assets/images/ico-comment.svg";
import ImgCaso1 from "../../assets/images/casos-1.png";
import ImgCaso2 from "../../assets/images/casos-2.png";
import ImgCaso3 from "../../assets/images/casos-3.png";
import ImgCaso4 from "../../assets/images/casos-4.png";

const MaxLimit = 8;

const data = [
  {
    name: "Diego",
    picture: ImgCaso1,
    degree: "propietario",
    business: "Meat & Grill",
    comment:
      "Logramos adquirir la sierra carnicera y empacadora al vacío para procesar más pedidos.",
  },
  {
    name: "Obed",
    picture: ImgCaso2,
    degree: "propietario",
    business: "Super La Palma",
    comment:
      "Adquirimos equipo de refrigeración para ofrecer productos de carnicería e incrementar las ventas.",
  },
  {
    name: "Enrique",
    picture: ImgCaso3,
    degree: "propietario",
    business: "Sanayi Soluciones Industriales",
    comment:
      "Gracias a Enpagos adquirimos maquinaria industrial CNC para atender a más clientes.",
  },
  {
    name: "",
    picture: ImgCaso4,
    degree: "",
    business: "Troqueles y Roll Formers Pacbet Maquinaria SA de CV.",
    comment:
      "En Enpagos estamos muy orgullosos de mostrarles uno de nuestros casos de éxito más recientes.",
  },
];

const responsive = {
  0: { items: 1 },
  300: { items: 1.1 },
  700: { items: 2.2 },
  1024: { items: 2.5 },
  1080: { items: 3 },
  1280: { items: 3 },
  1408: { items: 3 },
  1440: { items: 3 },
  1600: { items: 3 },
  1920: { items: 4 },
};

const createItems = (data, length, [handleClick]) => {
  let deltaX = 0;
  let difference = 0;
  const swipeDelta = 20;

  return data.map((item, i) => {
    return (
      <ItemCarousel
        data-value={i + 1}
        onMouseDown={(e) => (deltaX = e.pageX)}
        onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
        onClick={() => difference < swipeDelta && handleClick(i)}
      >
        <ItemCarouselInner>
          <ContItemPicture>
            <ItemPicture picture={item.picture} />
            <ContIcoComment>
              <img src={IcoCommentFile} alt="" />
            </ContIcoComment>
          </ContItemPicture>
          {item.name ? (
            <ItemName>
              {item.name}, {item.degree}
            </ItemName>
          ) : null}
          <ItemBusiness>{item.business}</ItemBusiness>
          <ItemDot />
          <ItemComment>{item.comment}</ItemComment>
        </ItemCarouselInner>
      </ItemCarousel>
    );
  });
};

const CasosExito = () => {
  const { width } = useWindowDimensions();
  const [disableDotsControls, setDisableDotsControls] = useState(true);
  const [dataSlider, setDataSlider] = useState(data);
  const [activeIndex, setActiveIndex] = useState(0);
  const [items] = useState(createItems(dataSlider, MaxLimit, [setActiveIndex]));

  const slidePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };
  const slideNext = () => {
    if (activeIndex < MaxLimit - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };
  const syncActiveIndex = ({ item }) => {
    setActiveIndex(item);
  };

  useEffect(() => {
    setDisableDotsControls(width > 1023);
  }, [width]);

  return (
    <ContCasos id="casos">
      <StylesCasos />
      <ContCasosInner className={"no-padding-desk container-fit_"}>
        <ContTitle>
          <Title1>
            {/*Más de 200 <Title2>casos de éxito</Title2>*/}
            Más de 200 <Title2>casos de éxito</Title2>
          </Title1>
        </ContTitle>
        <ContInfo>
          <Desc>
            No te descapitalices y{" "}
            <DescBold>obtén equipo de trabajo en pagos.</DescBold>
          </Desc>
        </ContInfo>
        <ContSlider>
          <div className="b-refs-buttons">
            <BtnCarousel className={"btn-carousel"} onClick={slidePrev}>
              <img src={ImgArrowPrev} alt="" />
            </BtnCarousel>
          </div>
          <AliceCarousel
            className={"alice-carousel"}
            disableDotsControls={disableDotsControls}
            disableButtonsControls
            items={items}
            activeIndex={activeIndex}
            responsive={responsive}
            onSlideChanged={syncActiveIndex}
            mouseTracking
            touchTracking
            touchMoveDefaultEvents
          />
          <div className="b-refs-buttons">
            <BtnCarousel className={"btn-carousel"} onClick={slideNext}>
              <img src={ImgArrowNext} alt="" />
            </BtnCarousel>
          </div>
        </ContSlider>
      </ContCasosInner>
    </ContCasos>
  );
};

export default CasosExito;
