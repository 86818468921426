import React, { useState } from "react";
import {
  ContInfo,
  ContSolicita,
  ContSolicitaInner,
  ContTitle,
  Desc,
  DescBold,
  Title1,
  Title2,
  ContButton,
  Boton,
} from "./SolicitaCredito.styles";
import ModalOne from "../Modals/ModalOne";
import ButtonWhatsApp from "../ButtonWhatsApp/ButtonWhatsApp";

const SolicitaCredito = () => {
  const [estadoModal1, cambiarEstadoModal1] = useState(false);

  return (
    <ContSolicita id="solicita">
      <ContSolicitaInner className={"no-padding-desk container-fit_"}>
        <ContTitle>
          <Title1>¡Solicita ahora y</Title1>
          <Title2>potencia tu negocio!</Title2>
        </ContTitle>
        <ContInfo>
          <Desc>
            Adquiere el equipo que necesitas{" "}
            <DescBold>y forma parte de nuestros casos de éxito.</DescBold>
          </Desc>
        </ContInfo>
        <ContButton>
          {/* <Button
            type="/"
            text="Solicita tu crédito"
            linkTo={'https://admin.dynamicore.io/public/people/form/a071db79d3d74ea9a56c0e754a69a330'}
          /> */}
          <Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>
            Cotiza tu equipo
          </Boton>
          <ButtonWhatsApp
            linkTo={'https://api.whatsapp.com/send?phone=5218135710836'}
            text="Contáctanos"
          />
        </ContButton>
      </ContSolicitaInner>
      <ModalOne estado={estadoModal1} cambiarEstado={cambiarEstadoModal1}>
       
      </ModalOne>
    </ContSolicita>
  );
};

export default SolicitaCredito;
