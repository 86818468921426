import styled from 'styled-components';

export const ContLogo = styled.div`
  width: 100%;
  height: 100%;
  
  @media(min-width: 300px) and (max-width: 1079px){
    width: 100%;
    height: 100%;
  }
`
export const ImgLogo = styled.img`
  width: 100%;
  height: 100%;
`
