import React from 'react';
import Layout from '../components/Layout/';
import Home from '../components/Home';
import HomeNew from '../components/HomeNew/HomeNew';
import Beneficios from '../components/Beneficios';
import NuestrosProveedores from '../components/NuestrosProveedores';
import CasosExito from '../components/CasosExito';
import ComoObtenerlo from '../components/ComoObtenerlo';
// import Financiamiento from '../components/Financiamiento';
import SolicitaCredito from '../components/SolicitaCredito';
import Footer from '../components/Footer';
import {NuestrosProductos} from '../components/NuestrosProductos/NuestrosProductos';
import SolicitaCreditoNew from '../components/SolicitaCreditoNew';

const Index = () => {
  const NEW_LANDING = false;

  return (
    <Layout>
      {NEW_LANDING ? (
        <>
          {/*<Financiamiento/>*/}
          <HomeNew/>
          <NuestrosProductos/>
          <CasosExito/>
          <SolicitaCredito/>
          <Footer/>
        </>
      ) : (
        <>
          <Home/>
          <NuestrosProductos/>
          <Beneficios/>
          <ComoObtenerlo/>
          <CasosExito/>
          <NuestrosProveedores/>
          <SolicitaCreditoNew/>
          <Footer/>
        </>
      )}
    </Layout>
  )
}

export default Index;
