import styled from "styled-components";
import { GlobalColors, GlobalFonts } from "../../globals";

export const ContHeader = styled.div`

  @media (min-width: 300px) and (max-width: 1023px) {
    padding-top: 80px;
  }
  @media (min-width: 1024px) and (max-width: 1880px) {
    padding-top: 81px;
  }
  @media (min-width: 1881px) and (max-width: 1920px) {
    padding-top: 111px;
  }
`

export const ContTitle = styled.div`
  padding: 10px 20px 10px 20px ;
  left: 83px;
  top: 29px;
  font-style: normal;
  font:45px ${GlobalFonts.fontBold};
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  margin: 30px 0 30px 0;

  @media (min-width: 300px) and (max-width: 1023px) {
  font:22px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1024px) and (max-width: 1880px) {
  font:45px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1881px) and (max-width: 1920px) {
  font:45px ${GlobalFonts.fontBold};
  }
`;

export const ContQuestions = styled.div`

  @media (min-width: 300px) and (max-width: 1023px) {
width: 100%;
height:auto;
margin: auto;
padding: 40px 0 40px 0;
  }
  @media (min-width: 1024px) and (max-width: 1880px) {
width: 50%;
height:auto;
margin: auto;
padding: 40px 0 40px 0;
  }
  @media (min-width: 1881px) and (max-width: 1920px) {
width: 50%;
height:auto;
margin: auto;
padding: 40px 0 40px 0;
  }
`

export const Title1 = styled.div`
display: inline;
  color: ${GlobalColors.colorPrimary};
`
export const Title2 = styled.div`
display: inline;
  color: ${GlobalColors.colorSecondary};
  `

export const Desc = styled.div`
  font: 24px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 80%;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1920px){
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 35px;
  }
`
