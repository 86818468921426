import styled, {css} from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContItemMenu = styled.div`
  width: auto;
  height: auto;
  background-color: #FBFFFF;
  text-align: center;
  @media(min-width: 1408px) and (max-width: 1599px){
    width: 120px;
    height: auto;
  }
`
export const CfgBtnLink = css`
  width: 100%;
  height: 100%;
  
  background-color: transparent;
  border: none;

  /* Display & Box Model */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  
  /* Typografhy */
  text-decoration: none;
  font: 15px ${GlobalFonts.fontBold};
  color: ${props => props.color};
  cursor: pointer;
  
  &:hover{
    color: ${GlobalColors.colorSecondary};
  }
  
  &[aria-current]{
    color: ${props => props.color};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding: 10px 10px 10px 0px;
    font-size: 13px;
    margin-right: 15px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding: 10px 10px 10px 0px;
    font-size: 15px;
    margin-right: 15px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    padding: 15px 15px 15px 0px;
    font-size: 15px;
    margin-right: 15px;
  }
  @media(min-width: 1920px){
    padding: 15px;
    font-size: 15px;
    margin-right: 15px;
  }
`
export const BtnLink = styled.button`
  ${CfgBtnLink}
`
export const ALink = styled.a`
  ${CfgBtnLink}
`
