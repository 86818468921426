import React, { useState } from "react";
import {
  BodyMenuMobile,
  ContBodyMenuMobile,
  ContFooterMenuMobile,
  ContItemsMenuMobile,
  ContMenuMobile,
  ImgMenu,
  ItemMenuMobile,
} from "./MenuMobile.styles";
import icoMenu from "../../assets/images/ico-hamburger.svg";
import icoClose from "../../assets/images/ico-close.svg";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
const MenuMobile = () => {
  const [onShowMenu, setOnShowMenu] = useState(false);

  const onShowMenuMobile = () => {
    console.log("onShowMenuMobile =>", !onShowMenu);
    setOnShowMenu(!onShowMenu);
  };

  const fnGoToElement = (el) => {
    setOnShowMenu(false);

    setTimeout(function () {
      const yOffset = -100;
      const titleElement = document.getElementById(el);
      // titleElement.scrollIntoView({ behavior: 'smooth' });
      const y =
        titleElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }, 500);
  };

  return (
    <ContMenuMobile>
      <ImgMenu
        src={onShowMenu ? icoClose : icoMenu}
        alt=""
        onClick={onShowMenuMobile}
      />
      {onShowMenu ? (
        <ContBodyMenuMobile>
          <BodyMenuMobile>
            <ContItemsMenuMobile>
              <a href="/conocenos" style={{ textDecoration: "none" }}>
                <ItemMenuMobile>Conócenos</ItemMenuMobile>
              </a>
              
              <Link to="/categorias" style={{ textDecoration: "none" }}>
                <ItemMenuMobile>
                 Categorías 
                </ItemMenuMobile>
              </Link>

              <Link to="/casos-de-exito" style={{ textDecoration: "none" }}>
                <ItemMenuMobile>
                  Casos de éxito
                </ItemMenuMobile>
              </Link>

              <Link to="/preguntas-frecuentes" style={{ textDecoration: "none" }}>
                <ItemMenuMobile>
                  Preguntas frecuentes
                </ItemMenuMobile>
              </Link>
            </ContItemsMenuMobile>
            <ContFooterMenuMobile>
              <div>2023 Enpagos©</div>
              <div>Todos los derechos reservados</div>
            </ContFooterMenuMobile>
          </BodyMenuMobile>
        </ContBodyMenuMobile>
      ) : null}
    </ContMenuMobile>
  );
};

export default MenuMobile;
