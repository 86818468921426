import styled from "styled-components";
import { GlobalColors, GlobalFonts } from "../../globals";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:100;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    height: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    height: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 100vw;
    height: 100%;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 100vw;
    height: 100%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 100%;
    height: 100%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 100vw;
    height: 100%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 100vw;
    height: 100%;
  }
  @media(min-width: 1920px){
    width: 100%;
    height: 100%;
  }
`;

export const ContenedorModal = styled.div`
 z-index:100;
  width: 549px;
  height: 318px;
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(199, 199, 11, 0.2) 0px 7px 29px 0px;
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    height: auto;
    padding-bottom: 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 70%;
    height: 35%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 549px;
  height: 328px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 549px;
  height: 318px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 549px;
  height: 318px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 549px;
  height: 318px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 549px;
  height: 318px;
  }
  @media(min-width: 1920px){
    width: 549px;
  height: 318px;
  }
`;

export const Encabezadomodal = styled.div`
  padding-top: 20px;
  width: auto;
  height: auto;
  left: 83px;
  top: 29px;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContTitle = styled.div`
  padding: 10px 20px 10px 20px ;
  left: 83px;
  top: 29px;
  font-style: normal;
  font:24px ${GlobalFonts.fontBold};
  font-weight: 700;
  line-height: 31px;

  @media (min-width: 300px) and (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 30px 10px 0px 10px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
  }
  @media (min-width: 1920px) {
  }
`;
export const Title1 = styled.div`
  color: ${GlobalColors.colorPrimary};
`
export const Title2 = styled.div`
  color: ${GlobalColors.colorSecondary};
`
export const BotonCerrar = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;

  width: 40px;
  height: 40px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #1766dc;

  &:hover {
    background: #f2f2f2;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const DescM = styled.div`
  font: 16px ${GlobalFonts.fontRegular};
  font-style: normal;
  color: ${GlobalColors.colorPrimary};
  font-weight: 400;
  line-height: 22px;
  padding: 10px 20px 10px 20px;

  @media (min-width: 300px) and (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 1023px) {
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
  }
  @media (min-width: 1920px) {
  }
`


export const contenido = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    color: ${GlobalColors.colorPrimary};
    font: ${GlobalFonts.fontBold}
  }
  p {
    font-size: 18px;
    margin-bottom: 10px;
    color: ${GlobalColors.colorPrimary};
  }
  img {
    width: 100%;
    vertical-align: top;
    border-radius: 20px;
  }
`;

export const contEjemplo = styled.div`
    margin-top: 100px;
    padding-bottom: 100px;
    width: auto;
    height: auto;
`;

export const ContBotones = styled.div`
  width: auto;
  height: auto;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  @media (min-width: 300px) and (max-width: 767px) {
  justify-content: center;
    display: block;
    gap: 10px;
  }
`;

export const Boton2 = styled.button`
  font: 15px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorBorder};
  width: 215px;
  height: 70px;
  display: block;
  margin-right: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s ease all;
  background: #fff;
  border-radius: 10px;
  border-color: ${GlobalColors.colorBorder};

  @media (min-width: 300px) and (max-width: 768px) {
    margin-bottom: 20px;
    margin-right: 0px;
    width: 215px;
    height: 70px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-right: 20px;
    width: 215px;
    height: 70px;
  }
  @media (min-width: 1024px) and (max-width: 1219px) {
    width: 215px;
    height: 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    width: 215px;
    height: 55px;
  }
  @media (min-width: 1408px) and (max-width: 1919px) {
    width: 215px;
    height: 60px;
  }
  @media (min-width: 1920px) {
    width: 215px;
  }
`;

export const ContenidoModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    vertical-align: top;
    border-radius: 3px;
  }
`;