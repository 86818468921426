import React from 'react';
import PendingResult from '../components/PendingResult';

const Pending = () => {

  return (
    <PendingResult/>
  );
};

export default Pending;
