import React from 'react';
import {ContHeaderResult} from './HeaderResult.styles';
import {Logo} from '../Logo';

const HeaderResult = () => {

  return (
    <ContHeaderResult>
      <Logo style={{width: '100%', height: '85%'}}/>
    </ContHeaderResult>
  );
};

export default HeaderResult;
