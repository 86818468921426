import React from 'react';
import LayoutResult from '../LayoutResult';
import {
  ContPendingResult,
  ContImgResult,
  ContInfo,
  ContResult,
  ContTitle,
  Desc,
  Title1,
  Title2,
} from './PendingResult.styles';
import imgResult from '../../assets/images/result-pending.svg';

const PendingResult = () => {

  return (
    <LayoutResult>
      <ContPendingResult>
        <ContResult className={'container-fit'}>
          <ContTitle>
            <Title1>¡Estamos validando</Title1>
            <Title2>tu información!</Title2>
          </ContTitle>
          <ContInfo>
            <Desc>Nos pondremos en contacto lo mas pronto posible.</Desc>
          </ContInfo>
          <ContImgResult>
            <img src={imgResult} alt=''/>
          </ContImgResult>
        </ContResult>
      </ContPendingResult>
    </LayoutResult>
  );
};

export default PendingResult;
