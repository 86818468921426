import React from 'react';
import {
  ContAcceptedResult,
  ContResult,
  ContTitle,
  Title1,
  Title2,
  ContInfo,
  Desc,
  ContImgResult,
} from './AcceptedResult.styles';
import LayoutResult from '../LayoutResult';
import imgResult from '../../assets/images/result-accepted.svg';
import {useLocation} from 'react-router-dom';
import {getQueryParams} from '../../utils';

const AcceptedResult = () => {
  const searchRaw = useLocation().search;
  const objParams = getQueryParams(searchRaw);
  const enganche = objParams.get('enganche');

  return (
    <LayoutResult>
      <ContAcceptedResult>
        <ContResult className={'container-fit'}>
          <ContTitle>
            <Title1>¡Tu financiamiento</Title1>
            <Title2>ha sido Pre-Autorizado</Title2>
            <Title2>con un {enganche}% de enganche!</Title2>
          </ContTitle>
          <ContInfo>
            <Desc>Nos pondremos en contacto lo mas pronto posible.</Desc>
          </ContInfo>
          <ContImgResult>
            <img src={imgResult} alt=''/>
          </ContImgResult>
        </ContResult>
      </ContAcceptedResult>
    </LayoutResult>
  );
};

export default AcceptedResult;
