import React from 'react'
import {
  ContBack,
  ContBeneficios,
  ContBeneficiosInner, ContCircle1, ContCircle2, ContDegraded1, ContDegraded2,
  Title1,
  Title2,
  Subtitle,
  ItemMetric,
  ContTitle,
  ContMetric,
} from './Metricas.styles';

const arrData = [{
  id: '1',
  title: '10',
  title2: '+',
  subtitle: 'Años en el mercado',
},{
  id: '2',
  title: '1,500',
  title2: '+',
  subtitle: 'Clientes atendidos',
},{
  id: '3',
  title: '500',
  title2: '+',
  subtitle: 'Créditos otorgados',
},{
  id: '4',
  title: '200',
  title2: '+',
  subtitle: 'Productos en nuestro catalogo',
},
];

const ItemData = ({title, title2, subtitle}) => {
  return (
    <ItemMetric>
      <ContTitle>
      <Title1>{title}<Title2>{title2}</Title2></Title1>
      </ContTitle>
      <Subtitle>{subtitle}</Subtitle>
    </ItemMetric>
  )
}

const Metric = () => {
  return arrData.map((item) => {
    return (
      <ItemData 
        key={item.id}
        title={item.title}
        title2={item.title2}
        subtitle={item.subtitle}
      />
    );
  });
};

const Metricas = () => {
  return (
    <ContBeneficios id='beneficios' className={'container-fit'}>
      <ContBeneficiosInner maxWidth={false}>
        <ContMetric>
          <Metric />
        </ContMetric>
      </ContBeneficiosInner>
      <ContBack/>
      <ContDegraded1/>
      <ContDegraded2/>
      <ContCircle1/>
      <ContCircle2/>
    </ContBeneficios>
  )
}

export default Metricas 
