import React from 'react';
import {ContMenu} from './Menu.styles';
import ItemMenu from '../ItemMenu';

const Menu = () => {

  return (
    <ContMenu>
      <ItemMenu text='Home' linkTo='' type={'/'}/>
      <ItemMenu text='Conócenos' linkTo='conocenos' type={'/'}/>
      <ItemMenu text='Categorías' linkTo='categorias' type={'/'}/>
      <ItemMenu text='Casos de éxito' linkTo='casos-de-exito' type={'/'}/>
      <ItemMenu text='Preguntas frecuentes' linkTo='preguntas-frecuentes' type={'/'}/>
    </ContMenu>
  );
};

export default Menu;
