import { useRef } from 'react';
import {
  ContNuestrosProductos,
  ContNuestrosProductosInner,
  ContTitle,
  Title1,
  ContInfo,
  Desc,
  ALeft,
  ARight,
  ContProducts, ItemProduct, ImgProduct, TitleProduct, LinkProduct, ContSlider, ProductSlider
} from './NuestrosProductos.styles';
import img1 from '../../assets/images/prod-vitrinas.png';
import img2 from '../../assets/images/prod-basculas.png';
import img3 from '../../assets/images/img-restaurantes.png';
import img4 from '../../assets/images/prod-cnc.png';
import img5 from '../../assets/images/img-oficina.png';
import img6 from '../../assets/images/img-panaderia.png';
import img7 from '../../assets/images/prod-refris.png';
//import img8 from '../../assets/images/img-talleres-mecanicos.png';

const URL = 'https://store.enpagos.mx';

const arProducts = [
  {
    id: 0,
    title: 'Abarrotes',
    bg: img1,
    link: `${URL}/product-category/abarrotes/`,
  }, {
    id: 1,
    title: 'Carnicería',
    bg: img2,
    link: `${URL}/product-category/carniceria/`,
  }, {
    id: 2,
    title: 'Restaurantes',
    bg: img3,
    link: `${URL}/product-category/restaurante/`,
  }, {
    id: 3,
    title: 'Taller de maquinado',
    bg: img4,
    link: `${URL}/product-category/taller-de-maquinados/`,
  }, {
    id: 4,
    title: 'Oficina',
    bg: img5,
    link: `${URL}/product-category/equipos-de-impresion-y-computo/`,
  }, {
    id: 5,
    title: 'Panadería',
    bg: img6,
    link: `${URL}/product-category/panaderia/`,
  },{
    id: 6,
    title: 'Seminuevos',
    bg: img7,
    link: `${URL}/product-category/seminuevo/`,
  },
  //{
    //id: 7,
    //title: 'Talleres mecánicos',
    //bg: img8,
    //link: `${URL}/product-category/carniceria/empacadoras-al-vacio/`,
  //},
];

const ItemProducts = ({title, bg, link}) => {

  return (
    <ItemProduct>
      <LinkProduct href={link} target={'_blank'}>
        <ImgProduct src={bg}/>
        <TitleProduct>
          {title}
        </TitleProduct>
      </LinkProduct>
    </ItemProduct>
  );
};

const Products = () => {
  return arProducts.map((item, index) => {
    return (
      <ItemProducts
        key={item.id}
        title={item.title}
        bg={item.bg}
        link={item.link}
      />
    );
  });
};

export const NuestrosProductos = () => {

const contentRef = useRef(null);
const handleScroll = (scrollOfSet) => {
  contentRef.current.scrollLeft += scrollOfSet
}

  return (
    <ContNuestrosProductos id="nuestros-productos">
      <ContNuestrosProductosInner className={"container-fit"}>
        <ContTitle>
          <Title1>
           Conoce nuestro catálogo 
          </Title1>
        </ContTitle>
        <ContInfo>
          <Desc>
Ayudamos a pequeños negocios, dando créditos en cómodos pagos para que adquieran maquinaria y equipos, sin importar qué tan pequeño o grande sea.
          </Desc>
        </ContInfo>
        <ProductSlider>
          <ALeft onClick={() => handleScroll(-250) }/>
          <ContSlider
            ref={contentRef} 
          >
            <ContProducts>
              <Products/>
            </ContProducts>
          </ContSlider>
          <ARight onClick={() => handleScroll(250) }/>
        </ProductSlider>
      </ContNuestrosProductosInner>
    </ContNuestrosProductos>
  )
}
