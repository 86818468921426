import React, {useState} from 'react';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  ContInfo,
  ContSolicita,
  ContSolicitaInner,
  ContTitle,
  Desc,
  Title1,
  Title2,
  ContForm,
} from './SolicitaCreditoNew.styles';
import {Container, Grid} from '@mui/material';
import ModalOne from '../Modals/ModalOne';
import DynamicForm from '../DynamicForm';

const SolicitaCreditoNew = () => {
  const lambdaURL = 'https://7lal8raqt6.execute-api.us-west-2.amazonaws.com/v1/enpagos-contact'
  const [estadoModal1, cambiarEstadoModal1] = useState(false);
  const [isProcessing] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const landingSchema = Yup.object().shape({
    name: Yup
    .string()
    .required('Campo requerido'),
    email: Yup
    .string()
    .email('Debe ser un correo valido')
    .required('Campo requerido'),
    type_business: Yup
    .string()
    .required('Campo requerido'),
    type_product: Yup
    .string()
    .required('Campo requerido'),
  })

  const handleSubmitData = async function (values) {
    const payload = values;

    if ( payload !== undefined && disabled === false ) {

      post(lambdaURL, payload, function (err, res) {
      if ( err ) {
      setLoading(false);
      setError(true);
      } else {
      setLoading(false);
      setSuccess(true);
      setDisabled(true);
      }
      });
    } else {
      console.log('no se puede procesar el formulario')
    }
  };

  const post = (URL, body, callback) => {
    setLoading(true);
    let req = new XMLHttpRequest();
    req.open('POST', URL, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.addEventListener('load', function () {
      if ( req.status < 400 ) {
        console.log(req.status)
        callback(null, JSON.parse(req.responseText));
      } else {
        callback(new Error('Request failed: ' + req.statusText));
      }
    });
    req.send(JSON.stringify(body));
  }

  const formBreakpoints = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
  };

  return (
    <ContSolicita id="solicita">
      <ContSolicitaInner className={'no-padding-desk container-fit_'}>
        <Container maxWidth={true} className={''}>
          <Grid container spacing={2}
            justifyContent="space-between"
            className={'full-width no-margin padding-form-fit'}
          >

            <Grid container xs={12} sm={12} md={12} lg={7}>
              <ContTitle>
                <Title1>¿Te interesa adquirir</Title1>
                <Title2>algún producto en pagos?</Title2>
              </ContTitle>
              <ContInfo>
                <Desc>
                  Ingresa tu información y un asesor se contactara contigo en breve.
                </Desc>
              </ContInfo>
            </Grid>

            <Grid container xs={12} sm={12} md={12} lg={5}>
              <ContForm>
                <DynamicForm
                  actions={[
                    {
                      color: 'primary',
                        label: 'Contáctenme',
                        processing: isProcessing,
                        type: 'submit',
                        className: 'button-gradient',
                        size: 'medium',
                        disabled: true,
                    },
                  ]}
                  fields={[
                    {
                      label: 'Ingresa tu nombre',
                        name: 'name',
                        type: 'text',
                        placeholder: 'Ejemplo: Juan Roberto',
                        display: {
                          breakpoints: formBreakpoints,
                        },
                    },
                    {
                      label: 'E-mail',
                      name: 'email',
                      type: 'email',
                      placeholder: 'Ejemplo: juan@tumail.com',
                      display: {
                        breakpoints: formBreakpoints,
                      },
                    },
                    {
                      label: '¿Cuál es el giro de tu negocio?',
                      name: 'type_business',
                      type: 'select',
                      placeholder: 'Ejemplo: tienda de abarrotes',
                      display: {
                        breakpoints: formBreakpoints,
                      },
                      settings: {
                        emptyElement: 'Selecciona',
                        options: [
                          {
                            id: 'Abarrotes',
                            name: 'Abarrotes',
                          },
                          {
                            id: 'Carnicería',
                            name: 'Carnicería',
                          },
                          {
                            id: 'Distribución de Carne, Pescado, Pollo',
                            name: 'Distribución de Carne, Pescado, Pollo',
                          },
                          {
                            id: 'Panadería, Paletería, Pastelería',
                            name: 'Panadería, Paletería, Pastelería',
                          },
                          {
                            id: 'Restaurante',
                            name: 'Restaurante',
                          },
                          {
                            id: 'Manufactura, Maquinados',
                            name: 'Manufactura, Maquinados',
                          },
                          {
                            id: 'Talleres de impresión',
                            name: 'Talleres de impresión',
                          },
                        ],
                      },
                    },
                    {
                      label: '¿En que tipo de producto estás interesad@?',
                      name: 'type_product',
                      type: 'select',
                      placeholder: 'Ejemplo: Refrigerador',
                      display: {
                        breakpoints: formBreakpoints,
                      },
                      settings: {
                        emptyElement: 'Selecciona',
                        options: [
                          {
                            id: 'Refrigeradores',
                            name: 'Refrigeradores',
                          },
                          {
                            id: 'Vitrinas',
                            name: 'Vitrinas',
                          },{
                            id: 'Básculas',
                            name: 'Básculas',
                          },{
                            id: 'Maquinaria CNC',
                            name: 'Maquinaria CNC',
                          },{
                            id: 'Impresoras',
                            name: 'Impresoras',
                          },{
                            id: 'Procesamiento',
                            name: 'Procesamiento',
                          },
                        ],
                      },
                    },
                    {
                      type: 'divider',
                      display: {
                        breakpoints: formBreakpoints,
                      },
                    },
                  ]}
                  handleSubmitData={handleSubmitData}
                  id="frmRegistro"
                  validationSchema={landingSchema}
                />
                {loading && <CircularProgress style={{ marginTop: '16px' }} />}
                {success && <span style={{ marginTop: '16px', color: '#20BDFF' }}>Datos enviados correctamente</span>}
              {error && <span style={{ marginTop: '16px', color: '#EB5757'  }} >Error del servicio, por favor intente mas tarde</span>}
              </ContForm>
            </Grid>

          </Grid>
        </Container>
      </ContSolicitaInner>
      <ModalOne estado={estadoModal1} cambiarEstado={cambiarEstadoModal1}>
      </ModalOne>
    </ContSolicita>
  );
};

export default SolicitaCreditoNew;
