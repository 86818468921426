import styled, { createGlobalStyle } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContNuestrosProductos = styled.div`
  width: auto;
  height: auto;
  padding: 60px 0 70px;
  background-color: ${GlobalColors.colorSecondaryLight};

  @media (min-width: 300px) and (max-width: 767px) {
    padding: 40px 0 50px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 40px 0 50px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    padding: 40px 0 50px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    padding: 40px 0 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    padding: 40px 0 50px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    padding: 40px 0 50px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    padding: 40px 0 50px;
  }
`;
export const ContNuestrosProductosInner = styled.div`
  width: auto;
  height: 100%;
  position: relative;
`;
export const ContTitle = styled.div`
  font: 56px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;

  @media (min-width: 300px) and (max-width: 767px) {
    margin: 0 20px 25px;
    font-size: 36px;
    line-height: 46px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 48px;
    line-height: 58px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 32px;
    line-height: 36px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 34px;
    line-height: 38px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 38px;
    line-height: 42px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 42px;
    line-height: 46px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 46px;
    line-height: 54px;
  }
  @media (min-width: 1920px) {
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 56px;
    line-height: 70px;
  }
`;
export const Title1 = styled.div`
  color: ${GlobalColors.colorPrimary};
`;
export const Title2 = styled.span`
  color: ${GlobalColors.colorSecondary};
`;
export const ContInfo = styled.div`
  width: 100%;

  @media (min-width: 300px) and (max-width: 1023px) {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const Desc = styled.div`
  font: 18px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  color: ${GlobalColors.colorPrimary};

  @media (min-width: 300px) and (max-width: 767px) {
    width: 90%;
    margin-bottom: 30px;
    line-height: 26px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 70%;
    margin-bottom: 40px;
    line-height: 32px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    margin-bottom: 20px;
    line-height: 24px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    margin-bottom: 20px;
    line-height: 26px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    margin-bottom: 20px;
    line-height: 28px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    margin-bottom: 30px;
    line-height: 28px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    margin-bottom: 30px;
    line-height: 32px;
  }
  @media (min-width: 1920px) {
    margin-bottom: 30px;
    line-height: 35px;
  }
`;
export const ContProducts = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
`;
export const ItemProduct = styled.div`
  width: 200px;
  height: 200px;
  background-color: ${GlobalColors.colorLight};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  &:hover {
     background-color: ${GlobalColors.colorSecondary};
  }
`;
export const LinkProduct = styled.a`
  width: 200px;
  height: 200px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimary};
  text-decoration: none;
`;
export const ImgProduct = styled.img`
  width: 70%;
  height: 70%;
  margin-bottom: 10px;
`;
export const TitleProduct = styled.div`
  margin-bottom: 10px;
`;
export const ContSlider = styled.div`
	gap: 2rem;
	list-style-type: none;
	padding: 0 2rem;
	margin: 0;
	display: flex;
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-x: scroll;
	flex-direction: row;
	background-color: transparent;
	border-radius: 10px;
	scroll-behavior: smooth;
  width: 80%


`;

export const ProductSlider = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
`

export const ALeft = styled.a`
  content:'';
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='30' cy='30' r='30' fill='url(%23paint0_linear_1_32)'/%3E%3Cpath d='M33.6733 20L36.2441 22.5898L27.3701 30.7783L36.2441 38.6431L33.6733 41.2329L22 30.7783L33.6733 20Z' fill='white'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1_32' x1='-66.1934' y1='223.209' x2='151.663' y2='123.369' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.310901' stop-color='%235433FF'/%3E%3Cstop offset='0.484297' stop-color='%2320BDFF'/%3E%3Cstop offset='0.843095' stop-color='%23A5FECB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin: 0 15px;

  @media (min-width: 300px) and (max-width: 1410px) {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' transform='rotate(-180 15 15)' fill='url(%23paint0_linear_1768_3156)'/%3E%3Cpath d='M12.9915 11.018L16.5187 14.5453L12.9915 18.0725C12.6369 18.4271 12.6369 18.9998 12.9915 19.3544C13.346 19.7089 13.9187 19.7089 14.2733 19.3544L18.446 15.1816C18.8005 14.8271 18.8005 14.2544 18.446 13.8998L14.2733 9.72709C13.9187 9.37254 13.346 9.37254 12.9915 9.72709C12.646 10.0816 12.6369 10.6635 12.9915 11.018V11.018Z' fill='white'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1768_3156' x1='-33.0967' y1='111.604' x2='75.8315' y2='61.6846' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%235433FF'/%3E%3Cstop offset='0.49' stop-color='%2320BDFF'/%3E%3Cstop offset='0.95' stop-color='%23A5FECB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  width: 30px;
  height: 30px;
  transform: rotate(180deg) translate(50%, 50%);
  left:0;
  top:50%;
  z-index:9;
  position: absolute;
  }
`

export const ARight = styled.a`
  content:'';
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='30' cy='30' r='30' transform='matrix(-1 0 0 1 60 0)' fill='url(%23paint0_linear_1_32)'/%3E%3Cpath d='M26.3267 20L23.7559 22.5898L32.6299 30.7783L23.7559 38.6431L26.3267 41.2329L38 30.7783L26.3267 20Z' fill='white'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1_32' x1='-66.1934' y1='223.209' x2='151.663' y2='123.369' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.310901' stop-color='%235433FF'/%3E%3Cstop offset='0.484297' stop-color='%2320BDFF'/%3E%3Cstop offset='0.843095' stop-color='%23A5FECB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin: 0 15px;

  @media (min-width: 300px) and (max-width: 1410px) {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' transform='rotate(-180 15 15)' fill='url(%23paint0_linear_1768_3156)'/%3E%3Cpath d='M12.9915 11.018L16.5187 14.5453L12.9915 18.0725C12.6369 18.4271 12.6369 18.9998 12.9915 19.3544C13.346 19.7089 13.9187 19.7089 14.2733 19.3544L18.446 15.1816C18.8005 14.8271 18.8005 14.2544 18.446 13.8998L14.2733 9.72709C13.9187 9.37254 13.346 9.37254 12.9915 9.72709C12.646 10.0816 12.6369 10.6635 12.9915 11.018V11.018Z' fill='white'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1768_3156' x1='-33.0967' y1='111.604' x2='75.8315' y2='61.6846' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%235433FF'/%3E%3Cstop offset='0.49' stop-color='%2320BDFF'/%3E%3Cstop offset='0.95' stop-color='%23A5FECB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  width: 30px;
  height: 30px;
  right:0;
  top:50%;
  transform: translate(50%, -50%);
  z-index:9;
  position:absolute;
  }
`
