import React from 'react'
import {
  ContNuestroObjetivo,
  ContNuestroObjetivoInner,
  ContTitle,
  Title1,
  Title2,
  Desc,
  DescBold,
  Img,
} from './NuestroObjetivo.styles';
import { Grid } from "@mui/material";
import ButtonWhatsApp from "../QueHacemos/ButtonWhatsApp/ButtonWhatsApp";

import testerImg from '../../assets/images/tester-img.png';


const NuestroObjetivo = () => {
  return (
    <ContNuestroObjetivo id='beneficios' className={'container-fit-spacing'}>
      <ContNuestroObjetivoInner maxWidth={false} className={'no-padding-desk'} style={{marginTop: '40px !important'}}>
        <Grid container spacing={2} >
          <Grid item sx md={6} >
            <ContTitle>
              <Title1>
                Nuestro <Title2>Objetivo</Title2>
              </Title1>
            </ContTitle>
            <Desc>
              Queremos ser <DescBold>el aliado financiero de confianza</DescBold> de los micro y pequeños negocios en México. Sabemos que el acceso al financiamiento puede ser un desafío para los negocios más pequeños, por lo que <DescBold>ofrecemos soluciones de financiamiento flexibles y accesibles</DescBold> para ayudarlos a alcanzar su máximo potencial. 
            </Desc>
            <Desc>
              Queremos ser la plataforma financiera que permita a los negocios crecer y mejorar su flujo de efectivo, <DescBold>y ser parte de su éxito empresarial.</DescBold>
            </Desc>
            <ButtonWhatsApp />
          </Grid>
          <Grid item md={6} style={{textAlign: 'center'}}>
            <Img src={testerImg}/>
          </Grid>
        </Grid>
      </ContNuestroObjetivoInner>
    </ContNuestroObjetivo>
  )
}

export default NuestroObjetivo 
