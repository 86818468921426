import React, { useState } from "react";
import {
  HeaderGlobalStyles,
  ContHeader,
  ContHeaderFirst,
  ContHeaderInner,Boton
} from "./Header.styles";
import { Logo } from "../Logo";
import Menu from "../Menu";
import { Grid } from "@mui/material";
import MenuMobile from "../MenuMobile";
import ModalOne from "../Modals/ModalOne";

const Header = () => {
  const [estadoModal1, cambiarEstadoModal1] = useState(false);

  return (
    <ContHeaderFirst>
      <HeaderGlobalStyles />
      <ContHeader>
        <ContHeaderInner container spacing={2}>
          <Grid item md={2}>
            <Logo />
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={1}
            lg={0}
          >
            &nbsp;
          </Grid>
          <Grid item xs={0} sm={0} md={6} lg={7} className={"header-mobile"}>
            <Menu />
          </Grid>
          <Grid item xs={0} sm={0} md={2} lg={2} className={"header-mobile"}>
          <Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>
            Ver catálogo
          </Boton>
          </Grid>
          <Grid item sm={3} md={0} className={"no-show-desk"}>
            <MenuMobile />
          </Grid>
        </ContHeaderInner>
      </ContHeader>
      <ModalOne estado={estadoModal1} cambiarEstado={cambiarEstadoModal1}/>
    </ContHeaderFirst>
  );
};

export default Header;
