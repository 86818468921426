import React from "react";
import { 
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@material-ui/icons";
import {
  ContQuestions,
  ContHeader,
  Desc,
  ContTitle,
  Title1,
  Title2,
} from "./Preguntas.styles";

const arQuestions = [ {
  id: 1,
  question: '¿Qué es Enpagos?',
  response: 'Enpagos es la plataforma financiera que estabas buscando para hacer crecer tu micro o pequeño negocio. Ofrecemos financiamiento de maquinaria y equipo, un sistema de punto de venta con tienda en línea conectado a WhatsApp para ofrecer servicio a domicilio, una terminal punto de venta y una tarjeta de crédito para la compra de inventario. Todo lo que necesitas para llevar tu negocio al siguiente nivel.',
},{
  id: 2,
  question: '¿Cómo funciona Enpagos?',
  response: 'Enpagos te lo pone fácil. Comienza realizando una pre-autorización en línea en nuestro sitio web para determinar si cumples con los requisitos de elegibilidad. Si cumples con ellos, recibirás un correo con el resultado y nuestro equipo de atención al cliente de Enpagos se pondrá en contacto contigo para ayudarte en el proceso de autorización y firma del crédito. ¡Y listo! Ya puedes empezar a hacer crecer tu negocio con Enpagos.',
},{
  id: 3,
  question: '¿Cuáles son los requisitos?',
  response: 'Queremos asegurarnos de que nuestros clientes sean elegibles para el financiamiento de Enpagos. Para ello, deberás cumplir con los siguientes requisitos: Contar con más de 4 meses con un negocio establecido Comprobar ventas o compras Identificación oficial Comprobantes de domicilio particular y de negocio',
},{
  id: 4,
  question: '¿Qué productos puedo comprar con Enpagos?',
  response: 'En nuestro catálogo encontrarás una gran variedad de productos para que puedas elegir los que mejor se adapten a tus necesidades. Visita nuestro sitio web https://store.enpagos.mx/ para ver lo que tenemos para ti.',
},{
  id: 5,
  question: '¿Ya realizaste tu pre-autorización? ¿Qué sigue?',
  response: 'Después de realizar la pre-autorización en línea, recibirás una notificación por correo electrónico sobre el resultado de esta. Si la pre-autorización es aprobada, nuestro equipo de atención al cliente se pondrá en contacto contigo para ayudarte en el proceso de autorización. ¡No te preocupes, en Enpagos te guiaremos en todo momento!',
}, ];

const ItemQuestion = ({question, answer}) => {

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{ question }</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          { answer }
        </Typography>
      </AccordionDetails>
    </Accordion>
  ); 
};

const Questions = () => {
  return arQuestions.map((item, index) => {
    return (
      <ItemQuestion
        key={item.id}
        question={item.question}
        answer={item.response}
      />
    );
  });
};


const Preguntas = () => {
  return(
    <ContHeader id="home" className={"container-fit"}>
      <ContTitle>
        <Title1>Preguntas <Title2>frecuentes</Title2></Title1>
      </ContTitle>
      <Desc>
        Encuentra solución a tus preguntas.
      </Desc>
      <ContQuestions>
        <Questions />
      </ContQuestions>
    </ContHeader>
  );
};

export default Preguntas;
