import styled from "styled-components";
import { GlobalFonts } from "../../../globals";

export const BtnWhats = styled.button`
  padding: 0;
  background-color: #08D040;
  margin-left: 30px;
  font: 15px ${GlobalFonts.fontBold};
  color: #ffffff;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 215px;
  height: 70px;
  border: 1px solid #08d040;
  border-radius: 10px;
  cursor: pointer;

  & > a {
    width: 100%;
    height: 100%;
    display: block;
    font: 15px ${GlobalFonts.fontBold};
    text-decoration: unset;
    color: #ffffff;
  }
  
  &:hover {
    background: #08d040;
    color: white;

    & > a {
      text-decoration: unset;
      color: #FFFFFF;
    }
  }
  @media (min-width: 300px) and (max-width: 767px) {
    width: 160px;
    height: 70px;
    margin-left: 0px;
    padding: 0px 10px;
    /* margin-top: 20px; */
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 0px;
    padding: 0px 35px;
    width: 215px;
    height: 70px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    width: 215px;
    height: 50px;
  }
  @media (min-width: 1080px) and (max-width: 1279px) {
    padding: 0px 30px;
    width: 215px;
    height: 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    width: 215px;
    height: 55px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    width: 215px;
    height: 60px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    width: 215px;
    height: 60px;
  }
  @media (min-width: 1920px) {
    width: 215px;
  }
`;
export const ContIco = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) and (max-width: 1079px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    width: 50px;
    height: 50px;
  }
  @media (min-width: 1920px) {
    width: 50px;
    height: 50px;
  }
`;
export const ContText = styled.div``;
export const ContInner = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
`;
