import React from "react";
import {BtnWhats, ContIco, ContInner, ContText} from './ButtonWhatsApp.styles';
import IcoWhatsApp from "../../../assets/images/ico-whatsapp.svg";
import {Link} from '@mui/material';

const ButtonWhatsApp = () => {
  return (
    <BtnWhats>
      <Link href={'https://api.whatsapp.com/send?phone=5218135710836'} target={'_blank'}>
        <ContInner>
          <ContIco>
            <img src={IcoWhatsApp} alt="" />
          </ContIco>
          <ContText>Contáctanos</ContText>
        </ContInner>
      </Link>
    </BtnWhats>
  );
};

export default ButtonWhatsApp;
