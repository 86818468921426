const getQueryParams = (s) => {
  if (!s || typeof s !== 'string' || s.length < 2) {
    return new Map();
  }

  const a = s
    .substr(1) // remove `?`
    .split('&') // split by `&`
    .map(x => {
      const a = x.split('=');
      return [a[0], a[1]];
    }); // split by `=`

  return new Map(a);
};

export default getQueryParams;
