import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import {
  SELECT_PROP_TYPES,
  SELECT_AUTOCOMPLETE_PROP_TYPES,
  SELECT_MENU_PROP_TYPES,
  SELECT_MULTIPLE_PROP_TYPES,
} from './constants';

function SelectAutocomplete(props) {
  const {
    disabled,
    error,
    events = {},
    label,
    name,
    helperText,
    size,
    value,
    onBlur,
    onChange,
  } = props;
  let { options = [] } = props;

  if (typeof options === 'function') {
    options = options();
  }

  return (
    <Autocomplete
      disabled={disabled}
      onChange={(event, value) => {
        const target = {
          name,
          value: value?.id,
        };

        onChange({
          target,
        });

        if (events.onChange) {
          events.onChange(target);
        }
      }}
      onBlur={onBlur}
      options={options}
      getOptionLabel={({ name }) => name}
      groupBy={({ groupBy }) => groupBy}
      defaultValue={options.find(_ => String(_.id) === String(value))}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          fullWidth
          size={size}
          error={error}
          helperText={helperText}
          label={label}
          variant="outlined"
        />)}
    />);
}

function SelectMenu(props) {
  const {
    disabled,
    emptyElement,
    error,
    events = {},
    group,
    helperText,
    label,
    name,
    onBlur,
    onChange,
    size,
    value,
  } = props;
  let { options = [] } = props;

  if (typeof options === 'function') {
    options = options();
  }

  const groups = group ? [...new Set(
    options.map(({ groupBy }) => groupBy),
  )] : [];

  return (
    <TextField
      autoComplete={true}
      disabled={disabled}
      error={error}
      fullWidth={true}
      helperText={helperText}
      label={label}
      name={name}
      select={true}
      SelectProps={{
        native: true,
      }}
      size={size}
      variant="outlined"
      value={value}
      onBlur={onBlur}
      onChange={(e) => {
        onChange(e);

        if (events.onChange) {
          events.onChange(e?.target);
        }
      }}
    >
      {emptyElement && (<option>{emptyElement}</option>)}
      {group && (groups.map((item, index) => {
        const opt = options.filter((_) => _.groupBy === item);

        return (opt.length ? <optgroup key={index} label={item}>
          {opt.map(({ id, name }, key) => (
            <option value={id} key={key}>
              {name}
            </option>
          ))}
        </optgroup> : <></>);
      }))}
      {!group && options.map(({ id, name }, key) => (
        <option value={id} key={key}>
          {name}
        </option>
      ))}
    </TextField>
  );
}

function SelectMultiple(props) {
  const {
    disabled,
    events = {},
    label,
    name,
    onChange,
    size,
  } = props;
  let { options = [], value = [] } = props;

  if (!Array.isArray(value)) {
    value = [];
  }

  if (typeof options === 'function') {
    options = options();
  }

  return (<FormControl
    fullWidth
    size={size}
    variant="outlined"
  >
    <InputLabel children={label} />
    <Select
      name={name}
      variant="outlined"
      id="demo-mutiple-checkbox"
      multiple
      value={value}
      onChange={(e) => {
        onChange(e);

        if (events.onChange) {
          events.onChange(e?.target);
        }
      }}
      input={<OutlinedInput />}
      renderValue={(selected) => `${selected.length} seleccionado${selected.length !== 1 ? 's' : ''}`}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.id}>
          {!disabled && (<Checkbox
            checked={value.includes(option.id)}
          />)}
          <ListItemText primary={option.name} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>);
}

function SelectControl(props) {
  const {
    disabled,
    label,
    name,
    settings: {
      autocomplete = false,
      emptyElement,
      group = false,
      multiple = false,
      options = [],
    },
    events = {},
    size,
    value,
    error,
    helperText,
    onBlur,
    onChange,
  } = props;
  const Control = multiple
    ? SelectMultiple
    : (autocomplete ? SelectAutocomplete : SelectMenu);

  return (
    <Control
      disabled={disabled}
      emptyElement={emptyElement}
      error={error}
      events={events}
      group={group}
      helperText={helperText}
      label={label}
      multiple={multiple}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      size={size}
      value={value}
    />
  );
}

SelectAutocomplete.propTypes = SELECT_AUTOCOMPLETE_PROP_TYPES;
SelectMenu.propTypes = SELECT_MENU_PROP_TYPES;
SelectMultiple.propTypes = SELECT_MULTIPLE_PROP_TYPES;
SelectControl.propTypes = SELECT_PROP_TYPES;

SelectControl.defaultProps = {
  disabled: false,
  settings: {
    autocomplete: false,
    group: false,
    options: [],
  },
  size: 'small',
  onChange: () => { },
  onBlur: () => { },
};

export default SelectControl;
