import React from 'react';
import {
  ContFooter, ContFooterInner, TextFooter, TextFooterAddress,
  TextFooterReg, LinkSection, SocialMedia, Links, FooLinks, FooIcoLinks, FooSvg} from './Footer.styles';

const FacebookIco = () => {
  return (
<FooSvg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M12,2C6.477,2,2,6.477,2,12c0,5.013,3.693,9.153,8.505,9.876V14.65H8.031v-2.629h2.474v-1.749 c0-2.896,1.411-4.167,3.818-4.167c1.153,0,1.762,0.085,2.051,0.124v2.294h-1.642c-1.022,0-1.379,0.969-1.379,2.061v1.437h2.995 l-0.406,2.629h-2.588v7.247C18.235,21.236,22,17.062,22,12C22,6.477,17.523,2,12,2z"/></FooSvg>
  );
};

const IntagramIco = () => {
  return (
<FooSvg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="24px" height="24px">    <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"/></FooSvg>
  );
};

const Footer = () => {

  return (
    <ContFooter>
      <ContFooterInner>
        <TextFooterAddress>Garza Sada 2116, Col. Roma, Monterrey, Nuevo Leon, México, 64700</TextFooterAddress>
        <TextFooter>
          QUALITY FINANCIAL SERVICES, S.A.P.I. DE C.V. SOFOM., E.N.R., Entidad No Regulada ("Enpagos"), para su constitución y operación con tal carácter, no requiere de autorización de la Secretaría de Hacienda y Crédito Público, no obstante, se encuentra sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores, únicamente para efectos de lo dispuesto por el artículo 56 de la Ley General de Organizaciones y Actividades Auxiliares del Crédito. Enpagos©. Todos los derechos reservados. Prohibida la reproducción total o parcial del contenido de este sitio.
        </TextFooter>
      </ContFooterInner>
      <LinkSection>
        <TextFooterReg>2022 Enpagos© | Todos los derechos reservados</TextFooterReg>
        <Links>
          <FooLinks href="https://store.enpagos.mx/terminos-y-condiciones/" underline='always'> Términos y condiciones </FooLinks>
          <FooLinks href="https://store.enpagos.mx/aviso-de-privacidad/" underline='always'> Aviso de privacidad </FooLinks>
          <FooLinks href="https://admin.dynamicore.io/public/people/form/a071db79d3d74ea9a56c0e754a69a330" underline='always'> Cotizar equipos </FooLinks>
        </Links>
        <SocialMedia>
          <FooIcoLinks href='https://www.facebook.com/enpagos.mx/'> { FacebookIco() } </FooIcoLinks>
          <FooIcoLinks href='https://www.instagram.com/enpagos.mx/'> { IntagramIco() } </FooIcoLinks>
        </SocialMedia>
      </LinkSection>
    </ContFooter>
  );
};

export default Footer;
