import React from "react";
import Layout from "../components/Layout/";
import Footer from "../components/Footer";
import SolicitaCredito from '../components/SolicitaCredito';
import Preguntas from '../components/Preguntas';

const PreguntasFrecuentes = () => {
  return (
      <Layout>
        <Preguntas />
        <SolicitaCredito/>
        <Footer />      
      </Layout>
  );
};

export default PreguntasFrecuentes;
