import React from 'react';
import RejectedResult from '../components/RejectedResult';

const Rejected = () => {

  return (
    <RejectedResult/>
  );
};

export default Rejected;
