import { createGlobalStyle } from 'styled-components'

import MontserratRegularEot from '../fonts/MontserratRegular.eot';
import MontserratRegularTtf from '../fonts/MontserratRegular.ttf';
import MontserratRegularWoff from '../fonts/MontserratRegular.woff';
import MontserratRegularWoff2 from '../fonts/MontserratRegular.woff2';
import MontserratRegularSvg from '../fonts/MontserratRegular.svg';

import MontserratMediumEot from '../fonts/MontserratMedium.eot';
import MontserratMediumTtf from '../fonts/MontserratMedium.ttf';
import MontserratMediumWoff from '../fonts/MontserratMedium.woff';
import MontserratMediumWoff2 from '../fonts/MontserratMedium.woff2';
import MontserratMediumSvg from '../fonts/MontserratMedium.svg';

import MontserratBoldEot from '../fonts/MontserratBold.eot';
import MontserratBoldTtf from '../fonts/MontserratBold.ttf';
import MontserratBoldWoff from '../fonts/MontserratBold.woff';
import MontserratBoldWoff2 from '../fonts/MontserratBold.woff2';
import MontserratBoldSvg from '../fonts/MontserratBold.svg';

export const FontFiles = createGlobalStyle`
  @font-face {
    font-family: 'MontserratRegular';
    src: url(${MontserratRegularEot});
    src: local('MontserratRegular'), local('MontserratRegular'),
        url(${MontserratRegularWoff2}) format('woff2'),
        url(${MontserratRegularWoff}) format('woff'),
        url(${MontserratRegularTtf}) format('truetype'),
        url('${MontserratRegularSvg}#MontserratRegular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MontserratMedium';
    src: url(${MontserratMediumEot});
    src: local('MontserratMedium'), local('MontserratMedium'),
        url(${MontserratMediumWoff2}) format('woff2'),
        url(${MontserratMediumWoff}) format('woff'),
        url(${MontserratMediumTtf}) format('truetype'),
        url('${MontserratMediumSvg}#MontserratMedium') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MontserratBold';
    src: url(${MontserratBoldEot});
    src: local('MontserratBold'), local('MontserratBold'),
        url(${MontserratBoldWoff2}) format('woff2'),
        url(${MontserratBoldWoff}) format('woff'),
        url(${MontserratBoldTtf}) format('truetype'),
        url('${MontserratBoldSvg}#MontserratBold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
`

export const GlobalFonts = {
  fontPrimary: 'MontserratRegular',
  fontSecondary: 'MulishMedium',
  fontLight: 'MontserratRegular',
  fontRegular: 'MontserratRegular',
  fontMedium: 'MontserratMedium',
  fontBold: 'MontserratBold',
  fontExtraBold: 'MontserratBold',
  fontBlack: 'MontserratBold',
}

