import styled, {createGlobalStyle} from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const StylesComoObtenerlo = createGlobalStyle`
  .alice-carousel {
    @media(min-width: 300px) and (max-width: 1023px){
      margin-top: 35px !important;
    }
  }
  .alice-carousel__wrapper {
    @media(min-width: 300px) and (max-width: 767px){
      height: 240px !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      height: 500px !important;
    }
    @media(min-width: 1024px) and (max-width: 1079px){
      height: 350px !important;
    }
    @media(min-width: 1080px) and (max-width: 1219px){
      height: 333px !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      height: 368px !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      height: 400px !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      height: 400px !important;
    }
    @media(min-width: 1920px){
      height: 505px !important;
    }
  }
  .alice-carousel__dots {
    float: left;
    @media(min-width: 300px) and (max-width: 1023px){
      float: none;
    }
  }
  .alice-carousel__dots-item {
    width: 30px !important;
    height: 30px !important;
    background-color: ${GlobalColors.colorBtnTextPrimaryHover} !important;

    @media(min-width: 300px) and (max-width: 1023px){
      width: 20px !important;
      height: 20px !important;
    }
    @media(min-width: 1024px) and (max-width: 1079px){
      width: 25px !important;
      height: 25px !important;
    }
    @media(min-width: 1080px) and (max-width: 1219px){
      width: 25px !important;
      height: 25px !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      width: 25px !important;
      height: 25px !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      width: 25px !important;
      height: 25px !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      width: 25px !important;
      height: 25px !important;
    }
  }
  .alice-carousel__dots-item:not(.__custom):hover,
  .alice-carousel__dots-item:not(.__custom).__active {
    width: 30px !important;
    height: 30px !important;
    background-color: ${GlobalColors.colorSecondary} !important;

    @media(min-width: 300px) and (max-width: 1023px){
      width: 20px !important;
      height: 20px !important;
    }
    @media(min-width: 1024px) and (max-width: 1079px){
      width: 25px !important;
      height: 25px !important;
    }
    @media(min-width: 1080px) and (max-width: 1219px){
      width: 25px !important;
      height: 25px !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      width: 25px !important;
      height: 25px !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      width: 25px !important;
      height: 25px !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      width: 25px !important;
      height: 25px !important;
    }
  }
  .b-refs-buttons {
    @media(min-width: 300px) and (max-width: 1023px){
      display: none;
    }
  }
`

export const ContComoObtenerlo = styled.div`
  width: auto;
  height: auto;
  padding-top: 45px;
`
export const ContTitle = styled.div`
  font: 56px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 30px;
    line-height: 40px;  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 34px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 32px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding-top: 25px;
    margin-bottom: 35px;
    font-size: 34px;
    line-height: 38px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 25px;
    margin-bottom: 35px;
    font-size: 38px;
    line-height: 42px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 25px;
    margin-bottom: 35px;
    font-size: 42px;
    line-height: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 30px;
    margin-bottom: 40px;
    font-size: 46px;
    line-height: 54px;
  }
  @media(min-width: 1920px){
    padding-top: 30px;
    margin-bottom: 50px;
    font-size: 56px;
    line-height: 70px;
  }
`
export const Title1 = styled.div`
  color: ${GlobalColors.colorPrimary};
`
export const Title2 = styled.span`
  color: ${GlobalColors.colorSecondary};
`
export const ContInfo = styled.div`
  width: 90%;
  
  @media(min-width: 300px) and (max-width: 1023px){
    width: 100%;
    text-align: center;
  }
`
export const TitleStep = styled.div`
  font: 28px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 38px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 38px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 38px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 38px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 38px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 38px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 38px;
  }
  @media(min-width: 1920px){
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 38px;
  }
`
export const MottoStep = styled.div`
  font: 36px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  color: ${GlobalColors.colorPrimary};

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 25px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 25px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 30px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 30px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 25px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 25px;
    font-size: 26px;
    line-height: 36px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 25px;
    font-size: 26px;
    line-height: 36px;
  }
  @media(min-width: 1920px){
    margin-bottom: 25px;
    font-size: 36px;
    line-height: 42px;
  }
`
export const DescStep = styled.div`
  font: 24px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1920px){
    margin-bottom: 45px;
    font-size: 24px;
    line-height: 35px;
  }
`
export const ItemCarousel = styled.div`
  width: 100% !important;
  height: 100% !important;
  background-image: url('${props => props.bg}');
  background-size: contain;
  background-repeat: no-repeat;
`
export const BtnCarousel = styled.button`
  width: 70px;
  height: 70px;
  margin-right: 15px;
  background: linear-gradient(65.38deg, #5433FF -161.14%, #20BDFF -13.51%, #A5FECB 125.08%);
  border: none !important;
  border-radius: 50%;
  cursor: pointer;
`
