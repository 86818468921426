import React from "react";
import {
  ContHeader,
  Media,
  ContTitle,
  Title1,
  Title2,
} from "./Video.styles";
const urlVideo = 'https://enpagos-public-assets.s3.us-west-2.amazonaws.com/casos.mp4';

const Video = () => {

  return(
    <ContHeader id="home" className={"container-fit"}>
      <ContTitle>
        <Title1>Casos de <Title2>éxito</Title2></Title1>
      </ContTitle>
    <Media 
      controls 
      controlsList={"nodownload"} 
      src={urlVideo}
      type="video/mp4"
      style={{ width: '75' }}
    >
      Your browser does not support the video tag.
    </Media>
</ContHeader>
  );
};

export default Video;
