import styled, { css } from "styled-components";
import { GlobalColors, GlobalFonts } from "../../globals";

export const ContButton = styled.div`
  display: block;
  background: #08d040;
  border-radius: 10px;
  float: right;

  &:hover {
    background: #14e04c;
  }
  @media (min-width: 300px) and (max-width: 767px) {
    margin-top: 20px;
    font-size: 13px;
    width: 215px;
    height: 50px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 13px;
    width: 215px;
    height: 50px;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    width: 215px;
    height: 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    width: 215px;
    height: 70px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    width: 215px;
    height: 70px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    width: 300px;
    height: 70px;
  }
  @media (min-width: 1920px) {
  padding: 0px 0px !important;
    width: 300px;
    height: 70px;
  }
`;
export const CfgBtnLink = css`
  width: 100%;
  height: 100%;
  padding: 0px 0px;
  background-color: transparent;
  border: none;
  /* Display & Box Model */
  display: flex;
  justify-content: center; 
  align-items: center;

  /* Typografhy */
  text-decoration: none;
  font: 15px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorBtnTextPrimary};
  cursor: pointer;

  &:hover {
    //color: ${GlobalColors.colorSecondary};
  }

  &[aria-current] {
    color: ${(props) => props.color};
  }

  @media (min-width: 300px) and (max-width: 767px) {
    font-size: 13px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 13px;
  }
  @media (min-width: 1024px) and (max-width: 1215px) {
    font-size: 13px;
    margin-right: 15px;
  }
  @media (min-width: 1216px) and (max-width: 1407px) {
    font-size: 13px;
    margin-right: 15px;
  }
  @media (min-width: 1408px) and (max-width: 1919px) {
    font-size: 13px;
    line-height: 25px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    font-size: 15px;
    line-height: 25px;
  }
  @media (min-width: 1920px) {
    line-height: 28px;
  }
`;
export const Btn = styled.button`
  ${CfgBtnLink}
`;
export const BtnAnchor = styled.a`
  padding: 10px 10px;
  ${CfgBtnLink}
`;
export const ContIco = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) and (max-width: 1079px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    width: 50px;
    height: 50px;
  }
  @media (min-width: 1920px) {
    width: 50px;
    height: 50px;
  }
`;
export const ContText = styled.div`
  display: flex;
  margin-left: 7px;
`;
