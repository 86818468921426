import React from "react";
import {
  Overlay,
  ContenedorModal,
  Encabezadomodal,
  BotonCerrar,
  Title1,
  Title2,
  ContTitle,
  Boton2,ContBotones,ContenidoModal
} from "./ModalOne.styles";
import Button from "../Button";

const ModalOne = ({ children, estado, cambiarEstado }) => {
  return (
    <>
      {estado && (
        <Overlay>
          <ContenedorModal>
            <Encabezadomodal>
              <ContTitle>
                <Title1>
                  Recuerda que otorgamos financiamiento a negocios con
                </Title1>
                <Title2>al menos 4 meses de operación</Title2>
              </ContTitle>
            </Encabezadomodal>
            <BotonCerrar onClick={() => cambiarEstado(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </BotonCerrar>
            <ContenidoModal>
            <ContBotones>
              <Boton2 onClick={() => cambiarEstado(false)}>
                En otro momento
              </Boton2>

              <Button
                className={"cont-button"}
                type="/"
                text="De acuerdo"
                linkTo={
                  //"https://admin.dynamicore.io/public/people/form/a071db79d3d74ea9a56c0e754a69a330"
                   "https://store.enpagos.mx"
                }
              />
            </ContBotones>
          </ContenidoModal>
          </ContenedorModal>
        </Overlay>
      )}
    </>
  );
};

export default ModalOne;
