import React from 'react';
import {ContLayoutResult} from './LayoutResult.styles';
import {FontFiles, GlobalStyles} from '../../globals';
import HeaderResult from '../HeaderResult';
import FooterResult from '../FooterResult';

const LayoutResult = ({children}) => {

  return (
    <ContLayoutResult>
      <FontFiles/>
      <GlobalStyles/>
      <HeaderResult/>
      {children}
      <FooterResult/>
    </ContLayoutResult>
  );
};

export default LayoutResult;
