import React from 'react';
import {ContButton, Btn, BtnAnchor, ContIco, ContText} from './ButtonWhatsApp.styles';
import IcoWhatsApp from '../../assets/images/ico-whatsapp.svg';

const ButtonWhatsApp = ({text, linkTo, type = '', location = 'normal'}) => {

  const fnGoto = (link) => {
    console.log('fnGoto => link =>', link);
  };

  return (
    <ContButton>
      {type === '#'
        ? (
          <Btn onClick={() => fnGoto(linkTo)}>
            <ContIco>
              <img src={IcoWhatsApp} alt=''/>
            </ContIco>
            <ContText>
              {text}
            </ContText>
          </Btn>
        ) : (
          <BtnAnchor href={linkTo} target='_blank'>
            <ContIco>
              <img src={IcoWhatsApp} alt=''/>
            </ContIco>
            <ContText>
              {text}
            </ContText>
          </BtnAnchor>
        )
      }
    </ContButton>
  );
};

export default ButtonWhatsApp;
