import React from 'react';
import {Btn, BtnAnchor, ContButton} from './Button.styles';

const Button = ({text, linkTo, type = '', location = 'normal'}) => {

  const fnGoto = (link) => {
    console.log('fnGoto => link =>', link);
  };

  return (
    <ContButton location={location}>
      {type === '#'
        ? (
          <Btn onClick={() => fnGoto(linkTo)}>
            {text}
          </Btn>
        ) : (
          <BtnAnchor href={linkTo} target='_blank'>
            {text}
          </BtnAnchor>
        )
      }
    </ContButton>
  )
}

export default Button;
