import styled from "styled-components";
import { GlobalColors, GlobalFonts } from "../../globals";

export const ContQuehacemos = styled.div`
  @media (min-width: 300px) and (max-width: 1079px) {
    padding: 90px 0px 0px 0px;
  }
  @media (min-width: 1080px) and (max-width: 1407px) {
    padding: 100px 0px 0px 0px;
  }
  @media (min-width: 1408px) and (max-width: 1599px){
    padding: 100px 0px 0px 0px;
  }
  @media (min-width: 1600px) {
    padding: 130px 0px 0px 0px;
  }
  
`;
export const ContTitle = styled.div`
  width: 100%;
  height: 100%;
  font: 54px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;

  @media (min-width: 300px) and (max-width: 767px) {
    font: 36px ${GlobalFonts.fontBold};
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font: 72px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    font: 44px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1080px) and (max-width: 1920px) {
    font: 54px ${GlobalFonts.fontBold};
  }
`;
export const Title1 = styled.div`
  color: ${GlobalColors.colorLink};
`;
export const Title2 = styled.span`
  color: ${GlobalColors.colorSecondary};
`;

export const ContInfo = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 300px) and (max-width: 1280px) {
    text-align: center;
    margin-bottom: 15px;
  }
  @media (min-width: 1408px) {
    margin-bottom: 0px;
  }
`;
export const Desc = styled.div`
margin: 20px 0px;
  width: 100%;
  font: 24px ${GlobalFonts.fontMedium};
  color:${GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: center;

  @media (min-width: 300px) and (max-width: 767px) {
    font: 20px ${GlobalFonts.fontMedium};
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font: 24px ${GlobalFonts.fontMedium};
  }
  @media (min-width: 1024px)and (max-width: 1439px) {
    font: 20px ${GlobalFonts.fontMedium};
    margin: 10px 0px;
  }
  @media (min-width: 1440px) and (max-width: 1599px) {
    font: 22px ${GlobalFonts.fontMedium};
    margin: 10px 0px;
  }
  @media (min-width: 1600px) {
    margin: 20px 0px;
  }
`;
