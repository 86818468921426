import {Grid, Link} from '@mui/material';
import React from "react";
import {
  ContNegocio,
  ContImgFirst,
  Title1,
  ContTitle,
  Desc,
  ContButton,
  ImgTwo,
  Contenedor, ALink, ContInner,
} from './Negocio.styles';
import quehacemos1 from "../../../assets/images/quehacemos1.png";
import quehacemos2 from "../../../assets/images/quehacemos2.png";
import quehacemos3 from "../../../assets/images/quehacemos3.png";
import quehacemos4 from "../../../assets/images/elevador.png";
import quehacemos5 from "../../../assets/images/oficina.png";
import quehacemos6 from "../../../assets/images/vitrina.png";
import { useState } from "react";
import ModalOne from "../../Modals/ModalOne";
import ItemEquipo from "../ItemEquipo/ItemEquipo";
import ButtonWhatsApp from "../ButtonWhatsApp/ButtonWhatsApp";

const STORE_ENPAGOS = 'https://store.enpagos.mx/';

const Negocio = () => {
  const [estadoModal1, cambiarEstadoModal1] = useState(false);
  return (
    <Contenedor id="negocio" className={"container-fit"}>


      <ContNegocio>
        <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2}>
          <Grid item sm={12} md={6} lg={6} order={{ xs: 1, sm: 1, md: 1, lg: 1 }} >
            <ContTitle>
              <Title1>
                Taller de maquinado o manufactura
              </Title1>
            </ContTitle>
            <Desc>Te ayudamos a comprar:</Desc>
          </Grid>

          <Grid item sm={12} md={6} lg={5} order={{ xs: 4, sm: 4, md: 3, lg: 3 }} >
            <Grid container>
              <Grid item sm={12} md={12} lg={12}>
                <ItemEquipo
                  Title1=""
                  Title2="Maquinaria CNC"
                  ContenidoText="Tornos, Fresadoras, Centros de Maquinado"
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <ContButton>
                {/*<Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>
                  Cotiza tu equipo
                </Boton>*/}
                <ALink>
                  <Link href={STORE_ENPAGOS} target={'_blank'}>
                    <ContInner>
                      Cotiza tu equipo
                    </ContInner>
                  </Link>
                </ALink>
                <ButtonWhatsApp/>
              </ContButton>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 2, sm: 2, md: 2, lg: 2 }} justifyContent="center" alignItems="center">
            <ContImgFirst>
              <ImgTwo src={quehacemos2} alt="" />
            </ContImgFirst>
          </Grid>
          <Grid item xs={0} sm={12} md={6} lg={6} order={{ xs: 4, sm: 3, md: 3, lg: 3 }} >
            &nbsp;
          </Grid>
        </Grid>
      </ContNegocio>


      <ContNegocio>
        <Grid container xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={12} md={7} lg={6.5} order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
            <ContTitle>
              <Title1>
                Tienda de abarrotes, carnicería, restaurante o panadería.
              </Title1>
            </ContTitle>
            <Desc>Te ayudamos a comprar:</Desc>
          </Grid>
          <Grid item xs={0} sm={12} md={5} lg={5.5} order={{ xs: 5, sm: 3, md: 3, lg: 3 }} >
                 &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6.5} order={{ xs: 4, sm: 4, md: 4, lg: 4 }}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <ItemEquipo
                  Title1="Equipo "
                  Title2="de refrigeración"
                  ContenidoText="Enfriadores, congeladores, vitrinas, máquinas de hielo." />
                <ItemEquipo
                  Title1="Equipo"
                  Title2="de procesamiento"
                  ContenidoText=" Sierras, rebanadoras, molinos, empacadora al vacío."/>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} spacing={2}>
                <ItemEquipo
                  Title1="Equipo"
                  Title2=" de cocción"
                  ContenidoText="Estufas, hornos, planchas, freidoras."
                />
                <ItemEquipo
                  Title1="Equipo"
                  Title2="de básculas"
                  ContenidoText="Etiquetadoras, comerciales, industriales."
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <ContButton>
                {/*<Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>
                  Cotiza tu equipo
                </Boton>*/}
                <ALink>
                  <Link href={STORE_ENPAGOS} target={'_blank'}>
                    <ContInner>
                      Cotiza tu equipo
                    </ContInner>
                  </Link>
                </ALink>
                <ButtonWhatsApp/>
              </ContButton>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5.5} order={{ xs: 2, sm: 2, md: 1, lg: 1 }} justifyContent="center" alignItems="center">
            <ContImgFirst>
              <ImgTwo src={quehacemos1} alt="" />
            </ContImgFirst>
          </Grid>
        </Grid>
      </ContNegocio>


      <ContNegocio>
        <Grid container xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={12} md={5} lg={6} order={{ xs: 2, sm: 2, md: 2, lg: 2 }} justifyContent="center" alignItems="center">
            <ContImgFirst>
              <ImgTwo src={quehacemos4} alt="" />
            </ContImgFirst>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6} order={{ xs: 1, sm: 1, md: 1, lg: 1 }} >
            <ContTitle>
              <Title1>Talleres mecánicos</Title1>
            </ContTitle>
            <Desc>Te ayudamos a comprar:</Desc>
          </Grid>
          <Grid item xs={0} sm={12} md={5} lg={6} order={{ xs: 4, sm: 3, md: 5, lg: 5 }} >
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6} order={{ xs: 4, sm: 4, md: 3, lg: 3}} >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7} lg={6}>
                <ItemEquipo
                  Title1=""
                  Title2="Equipos para tu taller"
                  ContenidoText="Rampas, Elevadores, Balanceadoras y Desmontadoras"
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <ContButton>
                {/*<Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>
                  Cotiza tu equipo
                </Boton>*/}
                <ALink>
                  <Link href={STORE_ENPAGOS} target={'_blank'}>
                    <ContInner>
                      Cotiza tu equipo
                    </ContInner>
                  </Link>
                </ALink>
                <ButtonWhatsApp/>
              </ContButton>
            </Grid>
          </Grid>
        </Grid>
        <ModalOne estado={estadoModal1} cambiarEstado={cambiarEstadoModal1}/>
      </ContNegocio>


      <ContNegocio>
        <Grid container xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={12} md={5} lg={6} order={{ xs: 2, sm: 2, md: 1, lg: 1 }} justifyContent="center" alignItems="center">
            <ContImgFirst>
              <ImgTwo src={quehacemos5} alt="" />
            </ContImgFirst>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6} order={{ xs: 1, sm: 1, md: 2, lg: 2 }} >
            <ContTitle>
              <Title1>Oficinas</Title1>
            </ContTitle>
            <Desc>Te ayudamos a comprar:</Desc>
          </Grid>
          <Grid item xs={0} sm={12} md={5} lg={6} order={{ xs: 4, sm: 3, md: 4, lg: 4 }} >
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6} order={{ xs: 4, sm: 4, md: 6, lg: 6}} >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7} lg={6}>
                <ItemEquipo
                  Title1=""
                  Title2="Equipos para oficina"
                  ContenidoText="Computadoras, Impresoras, Laptops, Celulares, Tablet y Copiadoras"
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <ContButton>
                {/*<Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>
                  Cotiza tu equipo
                </Boton>*/}
                <ALink>
                  <Link href={STORE_ENPAGOS} target={'_blank'}>
                    <ContInner>
                      Cotiza tu equipo
                    </ContInner>
                  </Link>
                </ALink>
                <ButtonWhatsApp/>
              </ContButton>
            </Grid>
          </Grid>
        </Grid>
        <ModalOne estado={estadoModal1} cambiarEstado={cambiarEstadoModal1}/>
      </ContNegocio>


      <ContNegocio>
        <Grid container xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={12} md={5} lg={6} order={{ xs: 2, sm: 2, md: 2, lg: 2 }} justifyContent="center" alignItems="center">
            <ContImgFirst>
              <ImgTwo src={quehacemos6} alt="" />
            </ContImgFirst>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6} order={{ xs: 1, sm: 1, md: 1, lg: 1 }} >
            <ContTitle>
              <Title1>Equipo o Maquinaria Seminuevo</Title1>
            </ContTitle>
            <Desc>Te ayudamos a comprar:</Desc>
          </Grid>
          <Grid item xs={0} sm={12} md={5} lg={6} order={{ xs: 4, sm: 3, md: 5, lg: 5 }} >
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6} order={{ xs: 4, sm: 4, md: 3, lg: 3}} >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7} lg={6}>
                <ItemEquipo
                  Title1=""
                  Title2=""
                  ContenidoText="Equipo de Refrigeración , Maquinaria CNC, Equipo de Oficina y Equipo para Talleres el cual se encuentra en nuestro catálogo."
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <ContButton>
                {/*<Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>
                  Cotiza tu equipo
                </Boton>*/}
                <ALink>
                  <Link href={STORE_ENPAGOS} target={'_blank'}>
                    <ContInner>
                      Cotiza tu equipo
                    </ContInner>
                  </Link>
                </ALink>
                <ButtonWhatsApp/>
              </ContButton>
            </Grid>
          </Grid>
        </Grid>
        <ModalOne estado={estadoModal1} cambiarEstado={cambiarEstadoModal1}/>
      </ContNegocio>

      <ContNegocio>
        <Grid container xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={12} md={5} lg={6} order={{ xs: 2, sm: 2, md: 1, lg: 1 }} justifyContent="center" alignItems="center">
            <ContImgFirst>
              <ImgTwo src={quehacemos3} alt="" />
            </ContImgFirst>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6} order={{ xs: 1, sm: 1, md: 2, lg: 2 }} >
            <ContTitle>
              <Title1>Negocios</Title1>
            </ContTitle>
            <Desc>Te ayudamos a comprar:</Desc>
          </Grid>
          <Grid item xs={0} sm={12} md={5} lg={6} order={{ xs: 4, sm: 3, md: 3, lg: 3 }} >
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6} order={{ xs: 4, sm: 4, md: 4, lg: 4 }} >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7} lg={6}>
                <ItemEquipo
                  Title1=""
                  Title2=""
                  ContenidoText="Punto de Venta Enpagos y tienda en línea conectados a WhatsApp para venta a domicilio."
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <ContButton>
                {/*<Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>
                  Cotiza tu equipo
                </Boton>*/}
                <ALink>
                  <Link href={STORE_ENPAGOS} target={'_blank'}>
                    <ContInner>
                      Cotiza tu equipo
                    </ContInner>
                  </Link>
                </ALink>
                <ButtonWhatsApp/>
              </ContButton>
            </Grid>
          </Grid>
        </Grid>
        <ModalOne estado={estadoModal1} cambiarEstado={cambiarEstadoModal1}/>
      </ContNegocio>
    </Contenedor>
  );
};

export default Negocio;
