import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContHeaderResult = styled.div`
  width: auto;
  height: auto;
  border-bottom: 1px solid ${GlobalColors.colorBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 60px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 60px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: 60px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    height: 60px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 80px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 80px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: 80px;
  }
  @media(min-width: 1920px){
    height: 80px;
  }
`
