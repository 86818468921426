import React from 'react'
import { 
    Title1,
    ContQuehacemos,
    ContTitle,
    Title2,
    ContInfo,
    Desc,
} from './Quehacemos.styles'

const Quehacemos = () => {
  return (
    <ContQuehacemos className={"container-fit"}>
        <ContTitle>
          <Title1>Nuestras  <Title2>categorías </Title2></Title1>
        </ContTitle>
        <ContInfo>
          <Desc>Contamos con distintas categorías donde podrás encontrar los distintos equipos que se ajustaran a las necesidades de tu negocio.</Desc>
        </ContInfo>
    </ContQuehacemos>
  )
}

export default Quehacemos
