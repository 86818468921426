import React, { useState } from "react";
import {
  ContBlockDesc,
  ContBlockLema,
  ContDesc,
  ContDescBold,
  ContHome,
  ContLema,
  ContLema1,
  ContLema2,
  StylesHome,
  ContButton,
  ItemCarousel,
  Boton,
} from "./Home.styles";
import { Container, Grid } from "@mui/material";
import imgFirst from "../../assets/images/first.png";
import imghome2 from "../../assets/images/ImgHome2.png";
import imghome3 from "../../assets/images/ImgHome3.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ModalOne from "../Modals/ModalOne";
import ButtonWhatsApp from "../QueHacemos/ButtonWhatsApp/ButtonWhatsApp";

const MaxLimit = 3;

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  900: { items: 1 },
  1024: { items: 1 },
  1224: { items: 1 },
};

const equipo = [
  {
    step: 1,
    title1: "¡Compra tu equipo o maquinaria",
    title2: " en pagos!",
    desc: "Plazos desde 24 hasta 60 meses.",
    desc2: "Buró de crédito no determinante.",
    img: imgFirst,
  },
  {
    step: 2,
    title1: "¡Un crédito a tu  medida ",
    title2: "para compra o renovar tu equipo!",
    desc: "Pre-autorización inmediata y cobertura",
    desc2: "de hasta el 100% de tu compra",

    img: imghome2,
  },
  {
    step: 3,
    title1: "Obtén tu maquinaria CNC ",
    title2: "sin miedo a descapitalizarte",
    desc: "Paga en plazos cómodos.",
    desc2: "Te ofrecemos 0% comisión por apertura.",
    img: imghome3,
  },
];

const createItems = (length, [handleClick]) => {
  let deltaX = 0;
  let difference = 0;
  const swipeDelta = 20;

  return Array.from({ length }).map((item, i) => (
    <ItemCarousel
      data-value={i + 1}
      className="item"
      onMouseDown={(e) => (deltaX = e.pageX)}
      onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
      onClick={() => difference < swipeDelta && handleClick(i)}
      bg={equipo[i].img}
    />
  ));
};


const Home = () => {
  const [estadoModal1, cambiarEstadoModal1] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [items] = useState(createItems(MaxLimit, [setActiveIndex]));
  const syncActiveIndex = ({ item }) => {
    setActiveIndex(item);
  };

  
  return (
    <ContHome id="home" className={"container-fit"}>
      <StylesHome />
      <Container maxWidth={false} className={"no-padding"}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={12} md={6} lg={7}>
            <ContBlockLema>
              <ContLema>
                <ContLema1>
                  {equipo[activeIndex].title1}{" "}
                  <ContLema2>{equipo[activeIndex].title2}</ContLema2>
                </ContLema1>
              </ContLema>
              <ContBlockDesc>
                <ContDesc>
                  <ContDescBold>{equipo[activeIndex].desc}</ContDescBold>
                </ContDesc>
                <ContDesc>{equipo[activeIndex].desc2}</ContDesc>
              </ContBlockDesc>
              <ContButton>
                <Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>
                 Ver catálogo 
                </Boton>
                <ButtonWhatsApp />
              </ContButton>
            </ContBlockLema>
          </Grid>
          <Grid
            item
            sm={12}
            md={0}
            lg={0}
            className={"cont-button"}
            order={{ xs: 1, sm: 1, md: 4, lg: 2 }}
          ></Grid>
          <Grid container xs={12} sm={12} md={6} lg={5}>
            <AliceCarousel
              className={"alice-carousel"}
              // disableDotsControls
              disableButtonsControls
              items={items}
              responsive={responsive}
              onSlideChanged={syncActiveIndex}
              mouseTracking
              touchTracking
              touchMoveDefaultEvents
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={0}
            className={"no-padding-left no-padding-top-mobile"}
          ></Grid>
        </Grid>
      </Container>
      <ModalOne estado={estadoModal1} cambiarEstado={cambiarEstadoModal1} />
    </ContHome>
  );
};

export default Home;
