import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContFooterResult = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  bottom: 0;
  
  @media(min-width: 300px) and (max-width: 767px){
    position: relative;
    margin-top: 30px;
  }
`
export const ContFooterResultInner = styled.div`
  position: relative;
  width: auto;
  height: auto;

  @media(min-width: 300px) and (max-width: 1079px){
    margin: 15px 20px !important;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    margin: 20px 50px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 20px 100px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 20px 100px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin: 20px 100px !important;
  }
  @media(min-width: 1920px) and (max-width: 3200px){
    margin: 20px 200px !important;
  }
`
export const TextFooterAddress = styled.div`
  margin-bottom: 15px;
  font: 14px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #828282;

  @media(min-width: 300px) and (max-width: 1279px){
    font-size: 10px;
    line-height: 12px;
  }
  @media(min-width: 1280px) and (max-width: 1620px){
    font-size: 12px;
    line-height: 14px;
  }
`
export const TextFooter = styled.div`
  margin-bottom: 15px;
  font: 14px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #828282;

  @media(min-width: 300px) and (max-width: 1279px){
    font-size: 10px;
    line-height: 12px;
  }
  @media(min-width: 1280px) and (max-width: 1620px){
    font-size: 12px;
    line-height: 14px;
  }
`
export const TextFooterReg = styled.div`
  font: 14px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 1279px){
    font-size: 10px;
    line-height: 12px;
  }
  @media(min-width: 1280px) and (max-width: 1620px){
    font-size: 12px;
    line-height: 14px;
  }
`
