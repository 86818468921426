import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Index from './views';
import Accepted from './views/Accepted';
import Pending from './views/Pending';
import Categorias from './views/Categorias';
import Conocenos from './views/Conocenos';
import Rejected from './views/Rejected';
import CasosDeExito from './views/CasosDeExito';
import PreguntasFrecuentes from './views/PreguntasFrecuentes';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={ <Index />} />
        <Route path='/categorias' element={ <Categorias />} />
        <Route path='/casos-de-exito' element={ <CasosDeExito />} />
        <Route path='/preguntas-frecuentes' element={ <PreguntasFrecuentes />} />
        <Route path='/conocenos' element={ <Conocenos />} />
        <Route path='/accepted' element={ <Accepted />}/>
        <Route path='/pending' element={ <Pending />} />
        <Route path='/rejected' element={ <Rejected />} />
      </Routes>
    </Router>
  );
}

export default App;
