import React from "react";
import { ALink, BtnLink, ContItemMenu } from "./ItemMenu.styles";
import { GlobalColors } from "../../globals";
import { Route, Link, Router } from "react-router-dom";
import { useLocation } from "react-router-dom";
const ItemMenu = ({ text, linkTo, type = "#" }) => {
  const searchRaw = useLocation();
  const fnGoToElement = (el) => {
    if (searchRaw.pathname === "/") {
      console.log(searchRaw);
    }
    setTimeout(function () {
      const yOffset = -100;
      const titleElement = document.getElementById(el);
      // titleElement.scrollIntoView({ behavior: 'smooth' });
      const y =
        titleElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }, 500);
  };

  return (
    <ContItemMenu>
      {type === "#" ? (
        <Link to="/" style={{ textDecoration: "none" }}>
          <BtnLink
            color={GlobalColors.colorPrimary}
            onClick={() => fnGoToElement(linkTo)}
          >
            {" "}
            {text}
          </BtnLink>
        </Link>
      ) : (
        <ALink color={GlobalColors.colorPrimary} href={`/${linkTo}`}>
          {text}
        </ALink>
      )}
    </ContItemMenu>
  );
};

export default ItemMenu;
