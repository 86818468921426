import React from "react";
import Layout from "../components/Layout/";
import Footer from "../components/Footer";
import QueHacemosOnPage from "../components/QueHacemosOnPage/QueHacemosOnPage";
import Metricas from "../components/Metricas/Metricas";
import NuestroObjetivo from "../components/NuestroObjetivo/NuestroObjetivo";
import SolicitaCredito from '../components/SolicitaCredito';

const Conocenos = () => {
  return (
      <Layout>
        <QueHacemosOnPage />
        <Metricas />
        <NuestroObjetivo />
        <SolicitaCredito/>
        <Footer />      
      </Layout>
  );
};

export default Conocenos;
