import styled from "styled-components";
import { GlobalColors, GlobalFonts } from "../../globals";

export const ContHeader = styled.div`

  @media (min-width: 300px) and (max-width: 1023px) {
    padding-top: 80px;
  }
  @media (min-width: 1024px) and (max-width: 1880px) {
    padding-top: 81px;
  }
  @media (min-width: 1881px) and (max-width: 1920px) {
    padding-top: 111px;
  }
`

export const Media = styled.video`
 width: 100%;
`

export const ContTitle = styled.div`
  padding: 10px 20px 10px 20px ;
  left: 83px;
  top: 29px;
  font-style: normal;
  font:45px ${GlobalFonts.fontBold};
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  margin: 30px 0 30px 0;

  @media (min-width: 300px) and (max-width: 1023px) {
  }
  @media (min-width: 1024px) and (max-width: 1880px) {
  }
  @media (min-width: 1881px) and (max-width: 1920px) {
  }
`;
export const Title1 = styled.div`
display: inline;
  color: ${GlobalColors.colorPrimary};
`
export const Title2 = styled.div`
display: inline;
  color: ${GlobalColors.colorSecondary};
  `
