import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';


export const ContNuestroObjetivo = styled.div`
  position: relative;
  width: auto;
  height: auto;
  margin-top: 40px !important;
`
export const ContNuestroObjetivoInner = styled.div`
display: flex;
flex-direction: row;
`
export const ContTitle = styled.div`
  text-Align: left;
  font: 32px ${GlobalFonts.fontBold} ;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  margin-bottom: 10px;

  @media (min-width: 300px) and (max-width: 767px) {
    margin: 0 20px 25px;
    font-size: 29px;
    line-height: 46px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 29px;
    line-height: 58px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 35px;
    line-height: 36px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 39px;
    line-height: 38px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 39px;
    line-height: 42px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 39px;
    line-height: 46px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 54px;
  }
  @media (min-width: 1920px) {
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 45px;
    line-height: 70px;
  }
  
`

export const Img = styled.img`
width: 100%;
height: auto;
min-width: 246px;
max-width: 540px;
margin: auto;
`

export const Title1 = styled.div`
  font: 35px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  color: ${GlobalColors.colorPrimary};
`
export const Title2 = styled.div`
  display: inline;
  color: ${GlobalColors.colorSecondary};
`
export const DescBold = styled.div`
  font-weight: 800;
  display:inline;
`
export const Desc = styled.div`
  font: 15px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  color: ${GlobalColors.colorPrimary};
  margin-top: 15px;

  @media (min-width: 300px) and (max-width: 767px) {
    margin-bottom: 30px;
    line-height: 24px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-bottom: 40px;
    line-height: 24px;
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    margin-bottom: 20px;
    line-height: 25px;
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    margin-bottom: 20px;
    line-height: 26px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    margin-bottom: 20px;
    line-height: 26px;
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    margin-bottom: 30px;
    line-height: 26px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    margin-bottom: 30px;
    line-height: 26px;
  }
  @media (min-width: 1920px) {
    margin-bottom: 30px;
    line-height: 26px;
  }
`;

export const Boton = styled.button`
  font: 15px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorBtnTextPrimary};
  color: #fff;
  width: 215px;
  height: 70px;
  display: block;
  border: none;
  margin-right: 15px;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s ease all;
  background: linear-gradient(
    73.69deg,
    #5433ff -18.91%,
    #20bdff 65.98%,
    #a5fecb 124.27%
  );
  border-radius: 10px;

  &:hover {
    background: linear-gradient(
      298.69deg,
      #5433ff -18.91%,
      #20bdff 65.98%,
      #a5fecb 124.27%
    );
  }
  @media (min-width: 300px) and (max-width: 767px) {
    margin-right: 15px;
    width: 160px;
    height: 70px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 215px;
    height: 70px;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    height: 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    height: 55px;
  }
  @media (min-width: 1408px) and (max-width: 1919px) {
    height: 60px;
  }
  @media (min-width: 1920px) {
  }
`;
