import styled, {createGlobalStyle} from 'styled-components';
import {Grid} from '@mui/material';
import {GlobalColors,GlobalFonts} from '../../globals';

export const HeaderGlobalStyles = createGlobalStyle`
  .header-mobile{
    @media(min-width: 300px) and (max-width: 1023px){
      display: none;
    }  
  }
`
export const Boton = styled.button`
  font: 15px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorBtnTextPrimary};
  color: #fff;
  width: 215px;
  height: 70px;
  display: block;
  border: none;
  margin-right: 15px;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s ease all;
  background: linear-gradient(
    73.69deg,
    #5433ff -18.91%,
    #20bdff 65.98%,
    #a5fecb 124.27%
  );
  border-radius: 10px;

  &:hover {
    background: linear-gradient(
      298.69deg,
      #5433ff -18.91%,
      #20bdff 65.98%,
      #a5fecb 124.27%
    );
  }
  @media (min-width: 300px) and (max-width: 767px) {
    margin-left: 0px;
  width: 170px;
    height: 70px;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    height: 50px;
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    height: 55px;
  }
  @media (min-width: 1408px) and (max-width: 1919px) {
    height: 60px;
  }
  @media (min-width: 1920px) {
  }
`;
export const ContHeaderFirst = styled.div`
  position: fixed;
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: 1px solid ${GlobalColors.colorBorder};
  z-index: 9;
`
export const ContHeader = styled.div`
  width: auto;
  height: 110px;
  //padding: 0 25px;
  background-color: transparent;
  // border-bottom: 1px solid ${GlobalColors.colorBorder};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media(min-width: 300px) and (max-width: 1079px){
    height: 64px;
    margin: 0 20px !important;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    height: 80px;
    margin: 0 50px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 80px;
    margin: 0 100px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 80px;
    margin: 0 100px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: 80px;
    margin: 0 100px !important;
  }
  @media(min-width: 1920px) and (max-width: 3200px){
    margin: 0 200px !important;
  }
`
export const ContHeaderInner = styled(Grid)`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: row !important;
`
