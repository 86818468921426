import React from 'react';
import AcceptedResult from '../components/AcceptedResult';

const Accepted = () => {

  return (
    <AcceptedResult/>
  );
};

export default Accepted;
