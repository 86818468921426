import styled from "styled-components";
import { GlobalColors, GlobalFonts } from "../../../globals";

export const ContTitle = styled.div`
  width: auto;
  height: auto;
  font: 36px ${GlobalFonts.fontBold};
  font-style: normal;
  line-height: 50px;
  text-align: start;

  @media (min-width: 300px) and (max-width: 767px) {
    font: 28px ${GlobalFonts.fontBold};
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  }
  @media (min-width: 768px) and (max-width: 911px) {
    padding: 0px 0px 0px 20px;
    font: 36px ${GlobalFonts.fontBold};
    text-align: center;
  }
  @media (min-width: 912px) and (max-width: 1023px) {
    padding: 0px 0px 0px 25px;
    font: 32px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    font: 26px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1080px) and (max-width: 1407px) {
    font: 28px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1408px) and (max-width: 1919px) {
    font: 36px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1920px) {
    width: auto;
  }
`;
export const Title1 = styled.div`
  color: ${GlobalColors.colorPrimary};
  @media (min-width: 300px) and (max-width: 767px) {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  }
`;
export const Title2 = styled.span`
  color: ${GlobalColors.colorSecondary};
`;
export const TitleEquipo1 = styled.div`
  width: 100%;
  margin-right: 5px;
  color: ${GlobalColors.colorPrimary};
  font: 28px ${GlobalFonts.fontBold};
  @media (min-width: 300px) and (max-width: 767px) {
    text-align: center;
    font: 26px ${GlobalFonts.fontBold};
  }
  @media (min-width: 768px) and (max-width: 911px) {
    width: 100%;
    padding: 0px 0px 16px 0px;
    justify-content: center;
    text-align: center;
    font: 28px ${GlobalFonts.fontBold};
  }
  @media (min-width: 912px) and (max-width: 1079px) {
    font: 20px ${GlobalFonts.fontBold};
  }
  @media (min-width: 1080px) and (max-width: 1920px) {
    font: 24px ${GlobalFonts.fontBold};
  }
`;

export const Equipo = styled.div`
  padding: 30px 0px 0px 0px;
  width: auto;
  height: auto;
  @media (min-width: 300px) and (max-width: 767px) {
    padding: 5px 0px 0px 0px;
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 911px) {
    padding: 20px 0px 0px 0px;
    text-align: center;
  }
  @media (min-width: 912px) and (max-width: 1023px) {
    padding: 10px 0px 0px 25px;
  }
  @media (min-width: 1024px) and (max-width: 1407px) {
    padding: 20px 0px 0px 0px;
  }
`;
export const EquipoTitle = styled.div`
  width: 100%;
  height: auto;
  font-weight: 700;
  line-height: 36px;
  font: 28px ${GlobalFonts.fontPrimary};
  font-style: normal;
  color: ${GlobalColors.colorPrimary};
  @media (min-width: 300px) and (max-width: 767px) {
    padding: 10px 0px 10px 0px;
    text-align: center;
    font: 20px ${GlobalFonts.fontPrimary};
  }
  @media (min-width: 768px) and (max-width: 911px) {
    padding: 0px 0px 16px 0px;
    justify-content: center;
    text-align: center;
    font: 28px ${GlobalFonts.fontPrimary};
  }
  @media (min-width: 912px) and (max-width: 1023px) {
    font: 20px ${GlobalFonts.fontPrimary};
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    font: 20px ${GlobalFonts.fontPrimary};
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    font: 28px ${GlobalFonts.fontPrimary};
  }
  @media (min-width: 1280px) and (max-width: 1407px) {
    font: 13px ${GlobalFonts.fontPrimary};
  }
  @media (min-width: 1408px) and (max-width: 1439px) {
    font: 18px ${GlobalFonts.fontPrimary};
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    font: 20px ${GlobalFonts.fontPrimary};
  }
  @media (min-width: 1920px) {
    font: 27px ${GlobalFonts.fontPrimary};
  }
`;
export const Contenido = styled.div`
  font-style: normal;
  font-weight: 500;
  font: 16px ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorPrimary};

  @media (min-width: 300px) and (max-width: 767px) {
    font: 20px ${GlobalFonts.fontMedium};
    color: ${GlobalColors.colorPrimary};
    font-weight: 500;
    line-height: 35px;
  }
  @media (min-width: 768px) and (max-width: 911px) {
    text-align: center;
    font: 24px ${GlobalFonts.fontMedium};
  }
  @media (min-width: 912px) and (max-width: 1023px) {
    padding: 5px 0px 0px 0px;
    font: 24px ${GlobalFonts.fontMedium};
  }
  @media (min-width: 1024px) and (max-width: 1079px) {
    padding: 5px 0px 0px 0px;
    font: 20px ${GlobalFonts.fontMedium};
  }
  @media (min-width: 1080px) and (max-width: 1219px) {
    padding: 10px 0px 0px 0px;
    font: 20px ${GlobalFonts.fontMedium};
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    font: 20px ${GlobalFonts.fontMedium};
    padding: 15px 0px 0px 0px;
  }
  @media (min-width: 1280px) and (max-width: 1439px) {
    font: 20px ${GlobalFonts.fontMedium};
    padding: 15px 0px 0px 0px;
  }
  @media (min-width: 1440px) and (max-width: 1599px) {
    font: 22px ${GlobalFonts.fontMedium};
    padding: 15px 0px 0px 0px;
  }
  @media (min-width: 1600px) {
    font: 24px ${GlobalFonts.fontMedium};
    padding: 20px 0px 0px 0px;
  }
`;
export const TitleEquipo2 = styled.span`
  color: ${GlobalColors.colorSecondary};
  @media (min-width: 300px) and (max-width: 767px) {
    font: 28px ${GlobalFonts.fontBold};
    color: ${GlobalColors.colorSecondary};
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 911px) {
    justify-content: center;
    font: 28px ${GlobalFonts.fontBold};
    color: ${GlobalColors.colorSecondary};
    font-weight: 700;
    font-size: 28px;
    padding: 0px 0px 16px 0px;
    text-align: start;
  }
`;