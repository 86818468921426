import React from 'react';
import {ContLayout} from './Layout.styles';
import Header from '../Header';
import {FontFiles, GlobalStyles} from '../../globals';

const Layout = ({children}) => {

  return (
    <ContLayout>
      <FontFiles/>
      <GlobalStyles/>
      <Header/>
      {children}
    </ContLayout>
  )
}

export default Layout;
