import React from "react";
import Layout from "../components/Layout/";
import Footer from "../components/Footer";
import Negocio from "../components/QueHacemos/Negocios/Negocio";
import Quehacemos from "../components/QueHacemos/Quehacemos";
import SolicitaCredito from '../components/SolicitaCredito';

const Categorias = () => {
  return (
    <Layout>
      <Quehacemos />
      <Negocio/>
        <SolicitaCredito/>
      <Footer />      
    </Layout>
  );
};

export default Categorias;
