import React from 'react'
import {
  ContQueHacemosInner,
  ContQueHacemos,
  Title1,
  Title2,
  ContTitle,
  Desc,
  DescBold,
  ImgFront,
} from './QueHacemosOnPage.styles';
import imgSample from '../../assets/images/sample-page.png'

const QueHacemosOnPage = () => {
  return (
    <ContQueHacemos>
      <ContQueHacemosInner className={"container-fit"}>
        <ContTitle>
          <Title1>
            ¿Quiénes <Title2>somos?</Title2> 
          </Title1>
          <Desc>
            Somos una plataforma financiera diseñada para ayudar a los micro y pequeños negocios a crecer y mejorar su flujo de efectivo. Ofrecemos soluciones de financiamiento flexibles y accesibles, como el arrendamiento de maquinaria y equipo, una terminal punto de venta, una tarjeta de crédito para la compra de inventario, y un sistema de punto de venta con tienda en línea conectado a WhatsApp para ofrecer servicio de venta a domicilio. 
            <DescBold>Estamos comprometidos con el éxito de los negocios en México y trabajamos para proporcionarles los recursos necesarios para alcanzar sus metas.
            </DescBold>
          </Desc>
          <ImgFront src={imgSample} />
        </ContTitle>
      </ContQueHacemosInner>
    </ContQueHacemos>
  )
}

export default QueHacemosOnPage 
